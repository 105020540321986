@import "../../../index.scss";
@import "../../../@theme/style.scss";

.data-table__date-time {
  display: flex;
  flex-direction: column;
  // align-items: center;
}

.title-column {
  text-align: left;
}

p {
  text-decoration: none;
  margin: 0;
  text-align: left;
}

.text-size-L {
  font-size: $textFontSizeL;
}

.text-size-M {
  font-size: $textFontSizeM;
}

.text-color-1 {
  color: $color1;
}

.text-color-black {
  color: $colorBlack;
}

.text-weight-500 {
  font-weight: 500;
}

span {
  text-decoration: none;
  margin: 0;
  text-align: left;
}

.text-id-code-order {
  color: $color1;
}

.text-id-code-customer {
  color: $color1;
}

.div-date-create {
  display: flex;
  // align-items: center;
  // justify-items: center;
  flex-direction: column;

  button {
    margin-bottom: 5px;
  }
}

.div-customer-name-phone {
  .text-name-customer {
    color: $color1;
    margin: 0;
    font-weight: $fontWeightL;
  }

  .text-phone-customer {
    color: $colorBlack;
    margin: 0;
  }
}

.div-service-order {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text-service {
    text-decoration: none;
  }
}

.div-date-work {
  .text-worktime {
    text-transform: capitalize;
  }
}

.div-collaborator {
  .text-pending-search {
    color: $color2;
    margin: 0;
  }

  .div-name-star {
    .div-name {
      p {
        // text-decoration-line: underline;
        color: $color1;
        margin: 0;
      }
    }

    .div-phone-star {
      display: flex;

      p {
        color: $colorBlack;
      }

      .div-star {
        display: flex;
        flex-direction: row;
        margin-left: 15px;

        .text-star {
          color: $colorBlack;
          text-decoration: none;
          margin: 0px 0px 0px 5px;
        }

        .icon-star {
          color: #f2cc0e;
          margin: auto;
        }
      }
    }
  }
}

.div-collaborator-avatar {
  display: flex;
  flex-direction: row;

  .div-avatar {
    margin-right: 10px;

    img {
      width: 50px;
    }
  }

  .div-name-star {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      text-decoration-line: underline;
      color: $color1;
      margin: 0;
    }

    .div-phone-star {
      display: flex;

      p {
        color: $colorBlack;
        text-decoration-line: unset !important;
      }

      .div-star {
        display: flex;
        flex-direction: row;
        margin-left: 15px;

        .text-star {
          color: $colorBlack;
          text-decoration: none;
          margin: 0px 0px 0px 5px;
        }

        .icon-star {
          color: #f2cc0e;
          margin: auto;
        }
      }
    }
  }
}

.div-type-transaction {
  p {
    text-align: center;
  }

  // .div-withdraw {
  //   background-color: $backGroundDiscount;
  //   padding: 0.2rem;
  //   border-radius: 6px;
  //   border: 1px solid $colorError;
  // }
  // .div-top-up {
  //   background-color: $colorSuccess;
  // }
}

.div-status-order {
  .text-status-pending {
    color: $color2;
    // border: 1px solid $color2;
    padding: 4px;
    border-radius: 5px;
    background-color: $backGroundColor2;
  }

  .text-status-confirm {
    color: $color1;
    border: 1px solid $color1;
    padding: 4px;
    border-radius: 5px;
    background-color: $backGroundColor1;
  }

  .text-status-doing {
    color: $color1;
    border: 1px solid $color1;
    padding: 4px;
    border-radius: 5px;
    background-color: $backGroundColor1;
  }

  .text-status-done {
    color: $colorSuccess;
    border: 1px solid $colorSuccess;
    padding: 4px;
    border-radius: 5px;
    background-color: $backGroundColorSuccess;
  }

  .text-status-cancel {
    color: $colorError;
    border: 1px solid $colorError;
    padding: 4px;
    border-radius: 5px;
    background-color: $backGroundColorError;
  }
}

.div-payment {
  display: flex;
  flex-direction: column;
}

.name_customer {
  display: flex;

  .text-name-customer {
    color: $colorBlack;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto 0px auto 24px;
  }
}

// phone_action_hide
.hide-phone {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .phone-text {
    color: $colorBlack;
    text-decoration: none;
    margin: 0;
  }

  .btn-eyes {
    background-color: transparent;
    border: none;
    color: $colorBlack;
    margin: 0;
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.status_request {
  .text-contacted {
    color: $colorSuccess;
  }

  .div-uncontact {
    .text-uncontact {
      color: $colorBlack;
    }

    .btn-contacted-deep2 {
      width: 5em;
      padding: 3px 0px;
      background-color: $colorSuccess;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-top: 5px;
      border-radius: 4px;
      cursor: pointer;

      .text-btn-contact {
        color: $colorWhite;
      }
    }
  }
}

.user_contact {
  display: flex;
  flex-direction: column;
}

.text-user {
  color: $color1;
}

.div-more {
  cursor: pointer;
  color: $color1;
}

// .current-status-handle {
//   color: $colorBlack;
//   padding: 5px 0px;
//   margin: 0px 15px;
//   background-color: #e1e4da3f;
//   border-radius: 20px;
//   width: 140px;
//   p {
//       text-align: center;
//   }
// }

.code_order_star {
  display: flex;
  flex-direction: column;
}

.id_order_start {
  display: flex;
  flex-direction: column;
}

.subject-ADMIN {
  span {
    // border: 1px solid $color1;
    border-radius: 4px;
    padding: 3px 10px;
    background-color: #fff5da;
    color: rgb(255 188 10);
  }
}

.subject-KH {
  span {
    // border: 1px solid $color1;
    border-radius: 4px;
    padding: 3px 10px;
    background-color: #d9f5f0;
    color: rgb(0 189 157);
  }
}

.subject-CTV {
  span {
    // border: 1px solid $color1;
    border-radius: 4px;
    padding: 3px 10px;
    background-color: #def1fb;
    color: rgb(37 160 226);
  }
}

/* ~~~ Css dành cho table ~~~ */
.table-data {
  box-shadow:
    rgba(67, 71, 85, 0.27) 0px 1px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.75em 2em;
  border-radius: $borderRadiusL;

  // margin-top: 16px;
  // border: 2px solid black;
  // Nội dung hiển thị
  .table-data__content {}

  // Phân trang
  .table-data__pagination {
    display: flex;
    justify-content: space-between;
    background-color: $colorWhite;
  }

  .table-data__footer {
    display: flex;
    justify-content: space-between;

    .table-data__footer--left {
      display: flex;
      align-items: center;
      gap: $gapL;

      .table-data__footer--left-select {
        display: flex;
        flex-direction: column;
        min-width: 150px;
        gap: $gapXS;

        .table-data__footer--left-select-label {
          display: flex;
          gap: $gapXS;
          align-items: center;

          span {
            font-weight: $fontWeightXL;
            font-size: $textFontSizeM;
          }
        }
      }
    }
  }
}

/* ~~~ Css dành cho bảng ~~~ */
.table__header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .table__header-content--left {
    display: flex;
    gap: $gapXS;

    .table__header-content--left-label {
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      color: $colorBlack;
    }

    .table__header-content--left-number {
      font-size: $textFontSizeM;
      font-weight: $fontWeightXL;
    }
  }

  .table__header-content--right {
    display: flex;
    gap: $gapL;
  }
}

/* ~~~ Css dành cho các trường hợp ~~~ */
/* 1. Trường hợp trạng thái */
.case__status {
  display: flex;
  align-items: center;
  width: 100%;

  .case_status--text {
    width: 100%;
    padding: 8px;
    border-radius: $borderRadiusL;
    font-weight: $fontWeightXL;
    font-size: $textFontSizeM;
    text-align: center;

    &.pending {
      color: $colorYellow;
      background-color: $colorExtraLightYellow;
    }

    &.done {
      color: $colorGreen;
      background-color: $colorExtraLightGreen;
    }

    &.confirm {
      color: $colorBlue;
      background-color: $colorExtraLightBlue;
    }

    &.doing {
      color: $colorBlue;
      background-color: $colorExtraLightBlue;
    }

    &.cancel {
      color: $colorRed;
      background-color: $colorExtraLightRed;
    }

    &.processing {
      color: $colorOrange;
      background-color: $colorExtraLightOrange;
    }
  }

}

/* 2. Trường hợp ngày giao dịch */
.case__withdrawal-date {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: $gapXS;
}

/* 3. Trường hợp kiểu giao dịch */
.case__withdrawal-type-transfer {
  border-radius: $borderRadiusL;
  padding: 4px 8px;
  width: fit-content;

  &.withdrawal {
    background-color: $colorRed;
  }

  &.deposit {
    background-color: $colorYellow;
  }

  .case__withdrawal-type-transfer {
    color: $colorWhite;
    // &.withdrawal {
    //   color: $colorRed;
    // }

    // &.deposit {
    //   color: $colorGreen;
    // }
  }
}

/* 4. Trường hợp trạng thái giao dịch */
.case__transfer-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .case__transfer-status--name {
    font-weight: $fontWeightXL;
    font-size: $textFontSizeM;
    padding: 6px 8px;
    // width: 100%;
    text-align: center;
    border-radius: $borderRadiusL;

    &.pending {
      background-color: $colorExtraLightYellow;
      color: $colorYellow;
    }

    &.confirm,
    &.revoke,
    &.transferred {
      background-color: $colorExtraLightBlue;
      color: $colorBlue;
    }

    &.done {
      background-color: $colorExtraLightGreen;
      color: $colorGreen;
    }

    &.cancel,
    &.out_date {
      background-color: $colorExtraLightRed;
      color: $colorRed;
    }

    &.holding,
    &.processing {
      background-color: $colorExtraLightOrange;
      color: $colorOrange;
    }

    &.other {
      background-color: $colorMoreLightGray;
      color: $colorStone;
    }
  }
}

/* 5. Trường hợp loại giao dịch */
.case__transfer-type-transfer {
  display: flex;
  align-items: center;
  justify-content: center;

  .case__transfer-type-transfer--text {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    // width: 100%;
    color: $colorWhite;
    padding: 6px 8px;
    border-radius: $borderRadiusL;
    text-align: center;

    &.withdraw {
      background-color: $colorRed;
    }

    &.top-up {
      background-color: $colorGreen;
    }

    &.other {
      background-color: $colorLightGray;
    }
  }
}

/* 6. Trường hợp thông tin cá nhân */
.case__information {
  display: flex;
  flex-direction: column;
  align-items: center;

  .case__information--info {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.3s all ease;


    .case__information--info-name {
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      color: $colorBlack;
      transition: 0.3s all ease;
    }

    .case__information--info-phone {
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      color: $colorLightGray
    }

    &:hover {
      .case__information--info-name {
        color: $colorBlue;
      }
    }
  }
}

/* 7. Trường hợp loại tài khoản */
.case__type-account {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $borderRadiusL;

  .case__type-account--label {
    font-weight: $fontWeightL;
    font-size: $textFontSizeM;
    padding: 6px 8px;
    text-align: center;
    border-radius: $borderRadiusL;
  }
}

/* 8. Trường hợp yêu cầu tạo bởi */
.case__create-by {
  display: flex;
  align-items: center;
  justify-content: center;

  .case__create-by--person {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.3s all ease;

    .case__create-by--person-name {
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      color: $colorBlack;
      transition: 0.3s all ease;
      text-transform: capitalize;
    }

    .case__create-by--person-phone {
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      color: $colorLightGray;
    }

    &:hover {
      .case__create-by--person-name {
        color: $colorBlue;
      }
    }
  }
}

/* 9. Trường hợp hợp xác nhận bởi */
.case__admin-verify {
  display: flex;
  align-items: center;
  justify-content: center;

  .case__admin-verify--info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .case__admin-verify--info-name {
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      color: $colorBlack;
      text-transform: capitalize;
    }

    .case__admin-verify--info-sub {
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      color: $colorLightGray;
    }
  }
}

/* 10. Trường hợp hợp ngày tạo */
.case__date-create {
  display: flex;
  flex-direction: column;

  &.center {
    align-items: center;
    justify-content: center;
  }

  .case__date-create-date {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    color: $colorBlack;
  }

  .case__date-create-time {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    color: $colorLightGray;
    text-transform: capitalize;
  }
}

// Trường hợp số thứ tự 
.case__numbering {
  display: flex;
  align-items: center;
  justify-content: center;

  // &.center {
  //   align-items: center;
  //   justify-content: center;
  // }

  .case__numbering--number {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    color: $colorBlack;
  }
}

/* 12. Trường hợp mã (_id) */
.case__code-id {
  display: flex;
  align-items: center;
  justify-content: center;

  .case__code-id--number {
    font-weight: $fontWeightL;
    font-size: $textFontSizeM;
    color: $colorBlue;
    transition: 0.3s all ease;

    &:hover {
      color: $colorDarkBlue;
    }
  }
}

/* 13. Trường hợp số tiền */
.case__money {
  display: flex;
  justify-content: end;

  .case__money--number {
    font-weight: $fontWeightL;
    font-size: $textFontSizeM;
    color: $colorBlack;
  }
}

/* 14. Trường hợp phương thức thanh toán */
.case__payment-out {
  display: flex;
  justify-content: center;
  align-items: center;

  .case__payment-out--label {
    font-weight: $fontWeightXL;
    font-size: $textFontSizeM;
    color: $colorBlack;
  }
}

/* 15. Trường hợp mã khuyến mãi */
.case__promotion-code {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;

  .case__promotion-code--brand {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;

    .case__promotion-code--brand-sub {
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      color: $colorLightGray;
    }

    .case__promotion-code--brand-name {
      font-size: $textFontSizeM;
      font-weight: $fontWeightXL;
      color: $colorBlack;
      text-transform: uppercase;
    }
  }

  .case__promotion-code--un-brand {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;

    .case__promotion-code--un-brand-coupon {
      font-size: $textFontSizeM;
      font-weight: $fontWeightXL;
      color: $colorBlack;
    }

    .case__promotion-code--un-brand-sub {
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      color: $colorLightGray;

      .case__promotion-code--un-brand-sub-service {
        padding-left: 4px;
      }
    }

  }
}

/* 16. Trường hợp hình ảnh mã khuyến mãi */
.case__promotion-image {
  display: flex;
  align-items: center;
  justify-content: center;

  .case__promotion-image-picture {
    height: 50px;
    width: 50px;
    border-radius: $borderRadiusL;
    border: 1px solid $colorMoreLightGray;
  }
}

/* 17. Trường hợp các tỉnh thành */
.case__promotion-province {
  display: flex;
  align-items: center;
  justify-content: center;

  .case__promotion-province--label {
    padding: 8px;
    background-color: $colorMoreLightGray;
    border-radius: $borderRadiusL;
    font-size: $textFontSizeM;
    font-weight: $fontWeightXL;
    color: $colorBlack;
  }

  .case__promotion-province--provinces {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gapS;
  }
}

/* 18. Trường hợp các trạng thái của khuyến mãi */
.case__promotion-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .case__promotion-status--name {
    font-weight: $fontWeightXL;
    font-size: $textFontSizeM;
    padding: 6px 8px;
    // width: 100%;
    text-align: center;
    border-radius: $borderRadiusL;

    &.upcoming {
      background-color: $colorExtraLightYellow;
      color: $colorYellow;
    }

    &.doing {
      background-color: $colorExtraLightGreen;
      color: $colorGreen;
    }

    &.done,
    &.out_of_date,
    &.out_of_stock {
      background-color: $colorExtraLightRed;
      color: $colorRed;
    }
  }
}

// Trường hợp hiển thị tên và số điện thoại khách hàng
.case__customer-name-phone {
  display: flex;
  flex-direction: column;
  gap: $gapXS;

  .case__customer-name-phone--name {
    font-size: $textFontSizeM;
    font-weight: $fontWeightXL;
    color: $colorBlue;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      color: $colorDarkBlue;
    }
  }

  .case__customer-name-phone--phone {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    color: $colorLightGray;
  }
}

// Trường hợp hiển thị tên dịch vụ và thời gian của dịch vụ
.case__service {
  display: flex;
  flex-direction: column;
  gap: $gapXS;

  .case__service--name {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    color: $colorBlack;

    &.high-light {
      font-weight: $fontWeightXL;
    }
  }

  .case__service-time-work {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    color: $colorLightGray;
  }
}

// Trường hợp hợp ngày với thứ trong tuần
.case__date-with-day {
  display: flex;
  flex-direction: column;
  gap: $gapXS;

  &.center {
    align-items: center;
    justify-content: center;
  }

  .case__date-with-day--header {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    color: $colorBlack;

    &.high-light {
      font-weight: $fontWeightXL;
    }
  }

  .case__date-with-day--sub {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    color: $colorLightGray;
    text-transform: capitalize;
  }
}

// Trường hợp phương thức thanh toán
.case__payment-method {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: $gapXS;

  .case__payment-method--title {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    color: $colorBlack;

    &.high-light {
      font-weight: $fontWeightXL;
    }
  }

  .case__payment-method--money {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    color: $colorLightGray;
  }
}

// Trường hợp mã đơn
.case__code_order {
  display: flex;

  .case__code_order--code {
    font-size: $textFontSizeM;
    font-weight: $fontWeightXL;
    color: $colorBlue;
    transition: all 0.3s ease;

    &:hover {
      color: $colorDarkBlue
    }
  }
}

// Trường hợp tên khách hàng, số điện thoại và hạng
.case__customer-name-phone-rank {
  display: flex;
  flex-direction: column;
  gap: $gapXS;

  .case__customer-name-phone-rank--name {
    font-size: $textFontSizeM;
    font-weight: $fontWeightXL;
    color: $colorBlue;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      color: $colorDarkBlue;
    }
  }

  .case__customer-name-phone-rank--phone {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    color: $colorLightGray;

    &.high-light {
      font-weight: $fontWeightXL;
    }
  }

  .case__customer-name-phone-rank--rank {
    display: flex;
    gap: $gapXS;
    align-items: center;


    .case__customer-name-phone-rank--rank-sub {
      color: $colorLightGray;
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
    }

    .case__customer-name-phone-rank--rank-level {
      padding: 4px;
      border-radius: $borderRadiusL;
      background-color: $colorExtraLightGray;
      border: 1px solid $colorLightGray;
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;

      &.new {
        background-color: $colorOrange;
        color: $colorWhite;
        border-color: $colorOrange;
      }

      &.member {
        background-color: $colorExtraLightOrange;
        color: $colorOrange;
        border-color: $colorOrange;
      }

      &.silver {
        background-color: $colorExtraLightSlate;
        color: $colorSlate;
        border-color: $colorSlate;
      }

      &.gold {
        background-color: $colorExtraLightYellow;
        color: $colorAmber;
        border-color: $colorYellow;
      }

      &.platinum {
        background-color: $colorExtraLightCyan;
        color: $colorBlue;
        border-color: $colorCyan;
      }
    }
  }
}

// Trường hợp tên đối tác, số điện thoại và số sao
.case__collaborator-name-phone-star {
  display: flex;
  flex-direction: column;
  gap: $gapXS;

  .case__collaborator-name-phone-star--finding {
    color: $colorOrange;
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
  }

  .case__collaborator-name-phone-star--name {
    font-size: $textFontSizeM;
    font-weight: $fontWeightXL;
    color: $colorBlue;
    transition: all 0.3s ease;
    cursor: pointer;
    
    &:hover {
      color: $colorDarkBlue
    }
  }

  .case__collaborator-name-phone-star--phone {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    color: $colorLightGray;
  }

  .case__collaborator-name-phone-star--star {
    display: flex;
    gap: $gapXS;

    .case__collaborator-name-phone-star--star-number {
      font-size: $textFontSizeM;
      font-weight: $fontWeightXL;
      color: $colorOrange;
    }
  }

}

// Trường hợp các trạng thái của phiếu phạt
.case__status-ticket {
  display: flex;
  align-items: center;
  width: 100%;

  .case_status-ticket--text {
    width: 100%;
    padding: 8px;
    border-radius: $borderRadiusL;
    font-weight: $fontWeightXL;
    font-size: $textFontSizeM;
    text-align: center;

    &.standby {
      color: $colorYellow;
      background-color: $colorExtraLightYellow;
    }

    &.done {
      color: $colorGreen;
      background-color: $colorExtraLightGreen;
    }

    &.doing {
      color: $colorBlue;
      background-color: $colorExtraLightBlue;
    }

    &.revoke,
    &.cancel {
      color: $colorRed;
      background-color: $colorExtraLightRed;
    }

    &.waiting &.processing {
      color: $colorOrange;
      background-color: $colorExtraLightOrange;
    }
  }

}

// Trường hợp đếm số thời gian còn lại
.case__timer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $gapM;
  
  .case__timer--element {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gapXS;


    .case__timer--element-number {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $colorMoreLightGray;
      border-radius: $borderRadiusL;
      background-color: $colorExtraLightGray;
      width: 30px;
      height: 30px;

      span {
        font-size: $textFontSizeM;
        font-weight: $fontWeightXL;
        color: $colorBlack;
      }
    }

    .case__timer--element-label {
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      color: $colorLightGray;
    }
  }
}

// Trường hợp trạng thái của các khuyến mãi 
.case__status_promotion {
  display: flex;
  align-items: center;
  width: 100%;

  .case__status_promotion--text {
    width: 100%;
    padding: 8px;
    border-radius: $borderRadiusL;
    font-weight: $fontWeightXL;
    font-size: $textFontSizeM;
    text-align: center;

    &.upcoming {
      color: $colorYellow;
      background-color: $colorExtraLightYellow;
    }

    &.doing {
      color: $colorBlue;
      background-color: $colorExtraLightBlue;
    }

    &.done {
      color: $colorGreen;
      background-color: $colorExtraLightGreen;
    }

    &.out_of_stock,
    &.out_of_date,
    &.cancel {
      color: $colorRed;
      background-color: $colorExtraLightRed;
    }
  }

}

/* Các trường chỉ hiển chữ mới */
.case__normal-text {
  display: flex;


  .case__normal-text--label {
    // text-align: center;
    font-weight: $fontWeightL;
    font-size: $textFontSizeM;
    color: $colorBlack;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.2;

    &.uppercase {
      text-transform: uppercase;
    }

    &.bold {
      font-weight: $fontWeightXL;
    }
  }

  &.center {
    justify-content: center;
    align-items: center;

    .case__normal-text--label {
      text-align: center;
    }
  }
}

/* Các trường chỉ hiển chữ */
.case__normal-text-old {
  font-weight: $fontWeightL;
  font-size: $textFontSizeM;
  color: $colorBlack;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}