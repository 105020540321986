@import "../../@theme/style.scss";

.btn-see {
  background-color: transparent;
  padding: 4px 8px;
  border: 2px solid $colorViolet;
  border-radius: $borderRadiusFull;
  color: $colorViolet;
  font-size: $textFontSizeM;
  font-weight: $fontWeightXL;
  cursor: pointer;
  transition: all 0.5s ease;
  
  &:hover {
    background-color: $colorViolet;
    color: $colorWhite;
  }
}

.div-item-more-collaborator {
  padding: 10px;
  border-bottom: 1px solid rgb(221, 221, 221);
  display: flex;
  justify-content: space-between;

  .div-name-more {
    display: flex;
    flex-direction: row;
    .text-number {
      color: rgb(0, 0, 0);
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      text-decoration: none;
    }

    .text-name {
      color: black;
      font-size: 12px;
      font-weight: 500;
      font-family: "Roboto", sans-serif;
      text-decoration: none;
      margin-left: 5px;
    }
  }

  .text-money-more {
    color: black;
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
  }
}

.div-date {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-bottom: 30px;

  .picker {
    width: 300px;
  }
}
