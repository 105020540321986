@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import "./@theme/style.scss";

html,body {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}

.div-container-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-shadow {
   box-shadow:
    rgba(67, 71, 85, 0.27) 0px 1px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.75em 2em;
}


// <div className="bg-white rounded-xl my-4 p-4 card-shadow border-gray-300 border">

.card-table {
  background-color: $colorWhite;
  border-radius: $borderRadiusL;
  padding: 12px 20px;
   box-shadow:
    rgba(67, 71, 85, 0.27) 0px 1px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.75em 2em;
}

.div-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 8px 0px;

  .box-filter {
    background-color: var(--bg-color);
    border-radius: 5px;
    padding: 1rem;
    @extend .b-shadow-2;

  }

  .b-shadow-2 {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  }

}


.div-flex-row-wrap-flex-start {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 8px 0px;
}

.flex-justify-content-flex-start {
  justify-content: flex-start !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.div-flex-row-wrap-reverse {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  margin: 8px 0px;
}

.div-flex-row-flex-start {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 8px 0px;
}

.div-flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 8px 0px;
}

.div-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}

.input-search {
  width: 100%;
  border-radius: 5px;
  margin-left: 15px;
  align-items: center;
  margin: 0px;
  height: 35px;
}

.btn-action-header {
  Button {
    border: none;
    border-radius: 5px;
    box-shadow: 2px 3px 2px rgb(216, 216, 216);
    margin: auto auto auto auto;
  }

  .btn-add {
    background-color: #8b5cf6;
    color: white;
  }
}

.text-select-dropdown {
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #19202c;
}

.div-search {
  width: 75%;
}

@media screen and (max-width: 900px) {
  .div-flex-row {
    flex-direction: column;
  }

  .div-flex-row-flex-start {
    flex-direction: column;
  }
}

//   @media screen and (max-width: 900px) {
//     .div-flex-row {
//         flex-direction: column;
//     }
//   }



.div-current-status-handle {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  align-items: center;

  .current-status-handle {
    cursor: unset;
    margin: 0px 15px;
    width: 140px;

    p {
      font-size: $textFontSizeM;
    }
  }
}

.current-status-handle {
  cursor: pointer;
  color: $colorBlack;
  padding: 6px 0px;
  background-color: #e1e4da3f;
  border-radius: 20px;
  width: 120px;

  p {
    text-align: center;
    font-size: $textFontSizeM;
  }
}

.card-status-handle-review {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;

  .item-card-status-handle-review {
    cursor: pointer;
    color: $colorBlack;
    margin: 0px 15px 0px 0px;
    padding: 6px 10px;
    border: 1px solid #e1e4da3f;
    background-color: #e1e4da3f;
    border-radius: 20px;
    width: 140px;

    p {
      text-align: center;
    }
  }
}

.status-pending {
  color: $colorPending !important;
  border: 1px solid $colorPending !important;
  background-color: $backGroundPending !important;
}

.status-not-process {
  color: #000000 !important;
  border: 1px solid #dedede !important;
  background-color: #f8f8f8 !important;
}

.status-processing {
  color: $colorPending !important;
  border: 1px solid $colorPending !important;
  background-color: $backGroundPending !important;
}

.status-done {
  color: $colorSuccess !important;
  border: 1px solid $colorSuccess !important;
  background-color: $backGroundColorSuccess !important;
}

.status-not-contact {
  color: $colorError !important;
  border: 1px solid $colorError !important;
  background-color: $backGroundColorError !important;
}

.status-disable {
  display: block;
  pointer-events: none !important;
  background-color: #cfc4c4 !important;
  color: linen !important;
  border: 1px solid #cfc4c4 !important;
}

.status-confirm {
  display: block;
  pointer-events: none !important;
  background-color: $backGroundConfirmDoing !important;
  color: $colorConfirmDoing !important;
  border: 1px solid $colorConfirmDoing !important;
}

.ant-btn-primary:not([disabled]) {
  background: #1677ff !important;
}

.ant-btn-primary:not([disabled]):hover {
  background: #1f4ce0 !important;
}


.ant-layout-sider::-webkit-scrollbar {
  // width: 2px;
  // transition-duration: 500ms;
  // :hover {
  display: none;
}

.ant-table-container::-webkit-scrollbar {
  width: 2px !important;
  display: none;
}

// Ant Design Menu
.ant-menu {
  background-color: #FCFEFF;
  border: 0px solid black !important;


  // padding: 0 0 10px 0;

  /* ~~~ Css của một item ~~~ */
  .ant-menu-item {
    // margin-bottom: 4px !important;
    // color: #909FAB;
    // height: fit-content !important;
    // padding-top: 14px !important;
    // padding-bottom: 14px !important;

    // Label
    .ant-menu-title-content {
      font-family: $fontFamily;
      font-style: $fontStyleNormal;
      font-weight: $fontWeightXL;
      font-size: $textFontSizeM;
      // font-size: 20px;
    }

    // Icon
    .ant-menu-item-icon {
      color: #475569;

    }
  }

  /* ~~~ Css của item selected ~~~ */
  .ant-menu-item-selected {

    // background-color: #143299;
    // Label
    .ant-menu-title-content {
      // color: #FD7B3C;
      font-family: $fontFamily;
      font-style: $fontStyleNormal;
      font-weight: $fontWeightXL;
      font-size: $textFontSizeM;
    }

    // Icon
    .ant-menu-item-icon {
      color: #F4F5FE;
    }
  }

  /* ~~~ Css của item là submenu ~~~ */
  .ant-menu-submenu {
    // margin-bottom: 4px !important;

    // Label và cả icon
    .ant-menu-submenu-title {
      // color: red;
      // height: fit-content !important;
      // padding-top: 14px !important;
      // padding-bottom: 14px !important;

      // Label
      .ant-menu-title-content {
        font-family: $fontFamily;
        font-style: $fontStyleNormal;
        font-weight: $fontWeightXL;
        font-size: $textFontSizeM;
      }

      // icon
      .ant-menu-item-icon {
        color: #475569;
      }

    }
  }

  /* ~~~ Css của item là submenu selected ~~~ */
  .ant-menu-submenu-selected {

    // Label và cả icon
    .ant-menu-submenu-title {
      background-color: #8b5cf6;
      // color: #FD7B3C;
      // height: fit-content !important;
      // padding-top: 14px !important;
      // padding-bottom: 14px !important;

      // Label
      .ant-menu-title-content {
        // color: #FD7B3C;
        font-family: $fontFamily;
        font-style: $fontStyleNormal;
        font-weight: $fontWeightXL;
        font-size: $textFontSizeM;
      }

      // icon
      .ant-menu-item-icon {
        color: #F4F5FE;
        ;
      }

    }
  }
}

.ant-layout-sider-children {
  background-color: #FCFEFF;
}

.ant-layout-sider-collapsed {

  // background-color: red;
  .ant-menu-item {
    // height: fit-content !important;
    // padding-top: 6px !important;
    // padding-bottom: 2px !important;
    // padding-right: 4px !important;
    // padding-left: 8px !important;
    // background: red !important;

    // Label
    .ant-menu-title-content {
      font-family: $fontFamily;
      font-style: $fontStyleNormal;
      font-weight: $fontWeightXL;
      font-size: $textFontSizeM;
    }

    // Icon
    .ant-menu-item-icon {}


  }
}

/* ~~~ Ant Design Dropdown ~~~ */
.ant-dropdown-menu {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  max-height: 300px;
  overflow: auto;
}

/* ~~~ Ant Design Table ~~~ */
.ant-table-thead p {
  color: $colorWhite;
  font-size: $textFontSizeM;
  font-weight: $fontWeightXL;
}
.ant-table-cell {
  padding: 16px 12px !important;
}

.ant-table tbody tr:nth-child(odd) td {
  background-color: #f5f7fd;
  // border: 1px solid #CCC;
  // padding: 10px;
}

.ant-table tbody tr:nth-child(even) td {
  // background-color: #f6f6f6;
  // border: 1px solid #CCC;
  // padding: 10px;
}

.ant-table-wrapper .ant-table {
  scrollbar-color: unset;
}

.ant-table-body {
  scrollbar-width: auto;
  scrollbar-color: auto;
}

// custom scrollbar
.ant-table-body::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  // border-radius: 8px;
}

.ant-table-body::-webkit-scrollbar-track {
  background-color: #ebe0f8;
}

.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #ebe0f8;
  background-color: #8b5cf6;
}

.ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: #7e53b2;
}

.ant-table-footer {
  border-radius: $borderRadiusL !important;
}
/* ~~~ Ant Design Progress ~~~ */
.ant-progress-text {
  font-weight: bold;
  font-size: 14px;
}

/* ~~~  Ant Design Tab ~~~ */
.ant-tabs-tab {
  font-size: $textFontSizeM !important;
  font-weight: $fontWeightXL !important;
  color: #475569 !important;
  padding: 16px 0px !important;
}

// Tab active bottom border
.ant-tabs-ink-bar {
  border-bottom: 5px solid $colorViolet;
  border-top-left-radius: $borderRadiusFull;
  border-top-right-radius: $borderRadiusFull;
}

// Tab active text
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $colorViolet !important;
}

// Tab background container
.ant-tabs-nav-wrap {
  background-color: white;
  padding: 0px 12px;
  border-radius: 6px;
   box-shadow:
    rgba(67, 71, 85, 0.27) 0px 1px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.75em 2em;
}

// Ẩn thanh menu của tabs khi chiều rộng không đủ sẽ hiển thị 
.ant-tabs-nav-operations {
  background-color: white;
  width: 0px !important;
  height: 0px !important;
  flex: none !important;
  // visibility: hidden;
}

// Ant Design Popover
.ant-popover-inner {
  // border-radius: 8px;
  // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  max-height: 350px !important;
  overflow: auto;
}

.ant-popover-inner::-webkit-scrollbar {
  width: 0.8em;
  // margin: 10px 10px;

}

.ant-popover-inner::-webkit-scrollbar-track {
  background-color: #ebe0f8;
  // margin-block: 1em;
  // border-radius: 8px;
}

.ant-popover-inner::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #ebe0f8;
  background-color: #8b5cf6;
}

.ant-popover-inner::-webkit-scrollbar-thumb:hover {
  background-color: #7e53b2;
}

/* ~~~ Ant Design Drawler ~~~ */
.ant-drawer-body {
  padding: 16px !important;
}

/* ~~~ Ant Design Switch ~~~ */
.ant-switch {
  background-color: $colorMoreLightGray !important;
}

.ant-switch-checked {
  background-color: $colorDarkViolet !important;
}

/* ~~~ Ant modal content ~~~ */
.ant-modal-content {
  padding: 12px !important;
}


/* Màu trắng của vòng tròn khi switch được bật */
// .ant-switch-checked .ant-switch-handle {
//   background-color: #ffffff !important;
// }
// .ant-switch-inner {
//   background-color: red;

//   .ant-switch-inner-checked {
//     background-color: blue;
//   }

//   .ant-switch-inner-unchecked {
//     background-color: yellow;
//   }
// }

// Ant Design Select
// .ant-select-selector {
//   height: 32px !important; /* Điều chỉnh chiều cao */
//   line-height: 32px !important; /* Điều chỉnh khoảng cách văn bản */
//   padding-top: 0;
//   padding-bottom: 0;
//   display: flex;
//   align-items: center; /* Căn giữa văn bản theo chiều dọc */
// }