@import "../../@theme/style.scss";

// Container chứa input
.input-custom {
    position: relative;

    .input-custom__input {
        border: 2px solid $colorMoreLightStone;
        border-radius: $borderRadiusL;
        width: 100%;
        font-size: $textFontSizeL;
        line-height: $lineHeightL;
        padding: 8px 20px;
        transition: 0.25s ease;
        outline: none;
        font-weight: $fontWeightL;
        font-size: $textFontSizeM;
        font-family: $fontFamily;
        color: $colorBlack;

        &.with-preview-image {
            padding: 8px 20px 8px 60px;
        }
    }

    .input-custom__label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 21px;
        user-select: none;
        color: #d6d6d6;
        pointer-events: none;
        transition: 0.3s ease;
        font-weight: $fontWeightL;
        font-size: $textFontSizeM;
        font-family: $fontFamily;

        &.with-preview-image {
            left: 65px;
        }

        &.have-describe {
            top: 34%;
        }

        .input-custom__label--required {
            color: #EF4444;
        }
    }

    .input-custom__describe {
        width: 100%;
        font-size: $textFontSizeM;
        color: $colorLightGray;
    }

    .input-custom__hide-image {
        position: absolute;
        top: 11px;
        right: 11.5px;
        padding: 13px 16px;
        background-color: #f1f5f9;
        border-left: 2px solid #eee;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .input-custom__image {
        display: flex;
        gap: 6px;
        cursor: pointer;
        align-items: center;
        padding: 0px 12px;
        position: absolute;
        // padding-left: 8px;
        top: 8px;
        left: 0px;
        // border-top-right-radius: 2.5px;
        // border-bottom-right-radius: 2.5px;
        border-right: 2px solid #eee;

        .input-custom__image--number {
            width: 28px;
            height: 24px;
            color: #999;
            outline: none;
            font-weight: $fontWeightL;
            font-size: $textFontSizeM;
        }

        .input-custom__image--img {
            width: 24px;
            height: 24px;
        }

        .input-custom__image--plus {
            font-weight: $fontWeightL;
            font-size: $textFontSizeM;
            color: $colorBlack;
        }



    }

    .input-custom__icon {
        position: absolute;
        top: 12px;
        right: 18px;
    }

    .input-custom__input:focus {
        border-color: $colorViolet;
    }

    .input-custom__input:not(:placeholder-shown)+.input-custom__label,

    .input-custom__input:focus+.input-custom__label {
        top: 0;
        left: 11px;
        padding: 0 10px;
        display: inline-block;
        background-color: white;
        color: $colorViolet;
        font-weight: $fontWeightXL;
    }

    .input-custom__text-area {
        display: flex;
        align-items: center;
        justify-content: center;

        // width: 100%;
        .input-custom__text-area--container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 160px;
            border: 2px dashed $colorLightGray;
            border-radius: $borderRadiusL;
            cursor: pointer;
            background-color: $colorExtraLightGray;
            transition: 0.3s all ease-out;

            &.showing-image {
                border: 0px;
                border-radius: $borderRadiusL;
            }

            &:hover {
                border-color: $colorViolet;

                .input-custom__text-area--container-content .input-custom__text-area--container-content-main-icon {
                    color: $colorViolet;
                }
            }

            .input-custom__text-area--container-image {
                width: 100%;
                height: 100%;
                border-radius: $borderRadiusL;
            }

            .input-custom__text-area--container-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-top: 20px;
                padding-bottom: 24px;


                .input-custom__text-area--container-content-main-icon {
                    transition: 0.3s all ease-out;
                    font-size: 32px;
                    color: $colorGray;

                }

                .input-custom__text-area--container-content-main-text {
                    margin-bottom: 8px;
                    font-size: $textFontSizeM;
                    color: $colorGray;

                    .text-bold {
                        font-weight: $fontWeightXL;
                    }
                }

                .input-custom__text-area--container-content-main-sub {
                    font-style: italic;
                    color: $colorGray;
                }
            }

            .input-custom__text-area--container-input {
                display: none;
            }
        }
    }
}

.input-custom-content {
    display: flex;
    flex-direction: column;

    .input-custom-content__child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        margin-bottom: 4px;
        transition: all 0.3s ease-in;
        border-radius: $borderRadiusL;
        cursor: pointer;

        span {
            font-weight: $fontWeightL;
            color: $colorBlack;
        }

        &.selected {
            background-color: $colorViolet;
            color: $colorWhite;

            span {
                font-weight: $fontWeightXL;
                color: $colorWhite;
            }
        }

        &:hover {
            background-color: $colorLightViolet;
            color: $colorBlack;

            span {
                font-weight: $fontWeightL;
                color: $colorBlack;
            }
        }

    }

    .input-custom-content__no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        gap: $gapXS;
        background-color: $colorExtraLightGray;
        border-radius: $borderRadiusL;
        
        .input-custom-content__no-data--label {
            color: $colorBlack;
            font-size: $textFontSizeM;
            font-weight: $fontWeightL;
        }
    }
}

input[type=file]::file-selector-button {
    margin-right: 10px;
    margin-top: 2px;
    border: none;
    background: #8b5cf6;
    padding: 1px 8px;
    border-radius: $borderRadiusS;
    color: #fff;
    cursor: pointer;
    transition: .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
    background: #c4b5fd;
}