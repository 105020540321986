@import "../../../@theme//style.scss";

.card-activities {
    display: flex;
    flex-direction: column;
    gap: $gapL;
    height: 100%;
    max-height: 470px;
    width: 100%;
    overflow-y: auto;
    padding-right: 8px;
    padding-bottom: 1px;

    .card-activities__child {
        display: flex;
        gap: $gapL;
        border-bottom: 2px solid $colorMoreLightGray;
        padding-bottom: 16px;

        .card-activities__child--header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: $gapXS;
            padding: 12px;
            border-radius: $borderRadiusXL;
            background-color: $colorExtraLightGray;
            border: 1px solid $colorMoreLightGray;
            width: fit-content;
            height: fit-content;

            .card-activities__child--header-day {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorLightGray;
            }

            .card-activities__child--header-number {
                font-size: $textFontSizeL;
                font-weight: $fontWeight2XL;
                color: $colorBlack;
            }

            &.punish {
                background-color: $colorExtraLightRed;
                border-color: $colorLightRed;

                .card-activities__child--header-day {
                    color: $colorLightRed;
                }
    
                .card-activities__child--header-number {
                    color: $colorRed;
                }
            }

            &.reward {
                background-color: $colorExtraLightGreen;
                border-color: $colorLightGreen;

                .card-activities__child--header-day {
                    color: $colorLightGreen;
                }
    
                .card-activities__child--header-number {
                    color: $colorGreen;
                }
            }
        }

        .card-activities__child--list {
            display: flex;
            flex-direction: column;
            gap: $gapL;
            padding-top: 12px;
            width: 100%;

            .card-activities__child--list-element {
                display: flex;
                flex-direction: column;
                gap: $gapS;
                position: relative;


                .card-activities__child--list-element-dot {
                    position: absolute;
                    top: 0;
                    left: -42px; /* Điều chỉnh giá trị này để dịch sang trái */
                    // transform: translateY(-0%); /* Căn giữa theo chiều dọc */
                    background-color: $colorLightGray;
                    width: 12px;
                    height: 12px;
                    border-radius: $borderRadiusFull;
                    border: 4px solid $colorExtraLightGray;

                    &.punish {
                        background-color: $colorLightRed;
                        border-color: $colorExtraLightRed;
                    }

                    &.reward {
                        background-color: $colorLightGreen;
                        border-color: $colorExtraLightGreen;
                    }
                }

                .card-activities__child--list-element-title {
                    display: flex;
                    // align-items: center;
                    justify-content: space-between;

                    .card-activities__child--list-element-title-content {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                        width: 75%;
                    }

                    .card-activities__child--list-element-title-number {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;

                        &.adding {
                            color: $colorGreen;
                        }

                        &.minus {
                            color: $colorRed;
                        }
                    }
                }

                .card-activities__child--list-element-time {
                    display: flex;
                    gap: $gapXS;
                    text-transform: capitalize;
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorLightGray;

                }
            }
        }

        &.last-item {
            border-bottom: 0px solid $colorMoreLightGray;
            padding-bottom: 0px;
        }
    }

    .card-activities__show-more {
        display: flex;
        align-items: center;
        justify-content: center;

        .card-activities__show-more--container {
            display: flex;
            gap: $gapXS;
            width: fit-content;
            cursor: pointer;

            .card-activities__show-more--container-label {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorLightGray;
            }
    
            .card-activities__show-more--container-button {
                font-size: $textFontSizeM;
                color: $colorLightGray;
                transform: rotate(90deg);
            }
        }
       
    }
}


.card-activities::-webkit-scrollbar {
    width: 0.4em;
}

.card-activities::-webkit-scrollbar-track {
    background-color: $colorExtraLightGray;
    margin-block: 1em;
    border-radius: 8px;
}

.card-activities::-webkit-scrollbar-thumb {
    border-radius: 8px;
    // border: 2px solid $colorExtraLightGray;
    background-color: $colorMoreLightGray;
}

.card-activities::-webkit-scrollbar-thumb:hover {
    background-color: $colorGray;
}


