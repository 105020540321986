@import "../../@theme/style.scss";

.login-admin {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #3E187A 0%, #C850C0 46%, #FFCC70 100%);

    // Khoảng trắng (để làm phần gợn sóng to hơn)
    .login-admin__blank {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: #994ECC;
    }

    .login-admin__wave {
        position: absolute;
        width: 100%;
        height: 195px;
        bottom: 0px;
        left: 0;
        background: url('../../assets/images/wave.svg');
        animation: wave 8s linear infinite;
        // background-position: 200px
    }

    .login-admin__wave::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 195px;
        bottom: 0px;
        left: 0;
        background: url('../../assets/images/wave.svg');
        opacity: .2;
        // background-position: 300px
        animation: wave-reverse 8s linear infinite;

    }

    .login-admin__wave::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 195px;
        bottom: 0px;
        left: 0;
        background: url('../../assets/images/wave.svg');
        opacity: .8;
        animation-delay: -4s;
        // background-position: 600px
        animation: wave 16s linear infinite;
    }

    @keyframes wave {
        0% {
            background-position: 0;
        }

        100% {
            background-position: 1280px;
        }
    }

    @keyframes wave-reverse {
        0% {
            background-position: 1280px;
        }

        100% {
            background-position: 0;
        }
    }

    .login-admin__form {
        display: flex;
        // margin-top: 210px;
        // padding: 16px;
        background-color: $colorMoreLightViolet;
        width: 600px;
        z-index: 1;
        height: 400px;
        border-radius: $borderRadiusL;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

        .login-admin__form--left {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            background-color: #4158D0;
            // background-image: linear-gradient(43deg, #3E187A 0%, #C850C0 46%, #FFCC70 100%);
            background-image: linear-gradient(43deg, #C850C0 46%, #3E187A 100%);

            height: 100%;
            width: 50%;
            border-top-left-radius: $borderRadiusL;
            border-bottom-left-radius: $borderRadiusL;
            // border-right: 1px solid black;

            .login-admin__form--left-child {
                background-color: transparent;
                border-radius: $borderRadiusL;
            }
        }

        .login-admin__form--right {
            display: flex;
            flex-direction: column;
            align-items: center;
            // gap: $gapL;
            background-color: $colorWhite;
            height: 100%;
            width: 50%;
            border-top-right-radius: $borderRadiusL;
            border-bottom-right-radius: $borderRadiusL;
            padding: 12px;
            overflow-y: hidden;

            .login-admin__form--right-logo {
                width: 100px;
                height: 80px;
                border-radius: $borderRadiusFull;
                padding-bottom: 12px;
            }

            .login-admin__form--right-login {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                align-items: center;
                // justify-content: center;
                gap: $gapL;
                opacity: 1;
                transition: all 0.5s ease-out;
                padding-top: 12px;

                &.hide {
                    visibility: hidden;
                    height: 0px;
                    opacity: 0;
                }

                .login-admin__form--right-login-header {
                    text-align: center;

                    span {
                        font-size: $textFontSizeXL;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }

                }

                .login-admin__form--right-login-input {
                    display: flex;
                    flex-direction: column;
                    gap: $gapL;
                    width: 100%;

                }

                .login-admin__form--right-login-line {
                    position: relative;
                    width: 70%;
                    height: 2px;
                    background-color: $colorMoreLightGray;
                    border-radius: $borderRadiusL;
                    margin: 0 auto;

                    .login-admin__form--right-login-line-other {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: $colorLightGray;
                        background-color: $colorWhite; // Đặt màu nền để chữ không bị che bởi đường thẳng
                        padding: 0 8px; // Tạo khoảng cách giữa chữ và viền nền
                    }
                }

                .login-admin__form--right-login-sign-up {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: $gapXS;

                    .login-admin__form--right-login-sign-up-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: $gapXS;

                        .login-admin__form--right-login-sign-up-container-label {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightL;
                            color: $colorBlack;

                            &.high-light {
                                font-weight: $fontWeightXL;
                                color: $colorDarkViolet;
                            }
                        }
                    }

                    .login-admin__form--right-login-forgot-password-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorDarkViolet;
                        }
                    }

                }
            }

            .login-admin__form--right-sign-up {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
                gap: $gapL;
                opacity: 1;
                transition: all 0.5s ease-out;

                &.hide {
                    visibility: hidden;
                    height: 0px;
                    opacity: 0;
                }

                .login-admin__form--right-sign-up-header {
                    text-align: center;

                    span {
                        font-size: $textFontSizeXL;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }

                }

                .login-admin__form--right-sign-up-input {
                    display: flex;
                    flex-direction: column;
                    gap: $gapL;
                    width: 100%;
                }

                .login-admin__form--right-sign-up-line {
                    position: relative;
                    width: 70%;
                    height: 2px;
                    background-color: $colorMoreLightGray;
                    border-radius: $borderRadiusL;
                    margin: 0 auto;

                    .login-admin__form--right-sign-up-line-other {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: $colorLightGray;
                        background-color: $colorWhite; // Đặt màu nền để chữ không bị che bởi đường thẳng
                        padding: 0 8px; // Tạo khoảng cách giữa chữ và viền nền
                    }
                }

                .login-admin__form--right-sign-up-sign-up {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: $gapXS;

                    .login-admin__form--right-sign-up-sign-up-label {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightL;
                        color: $colorBlack;

                        &.high-light {
                            font-weight: $fontWeightXL;
                            color: $colorDarkViolet;
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 640px) {
    .login-admin {
        position: relative;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #4158D0;
        background-image: linear-gradient(43deg, #3E187A 0%, #C850C0 46%, #FFCC70 100%);
        // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        // background: url('../../../assets/images/bg.png');
        // background-size: cover;

        // Khoảng trắng (để làm phần gợn sóng to hơn)
        .login-admin__blank {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 50px;
            background-color: #994ECC;
        }

        .login-admin__wave {
            position: absolute;
            width: 100%;
            height: 195px;
            bottom: 0px;
            left: 0;
            background: url('../../assets/images/wave.svg');
            animation: wave 8s linear infinite;
            // background-position: 200px
        }

        .login-admin__wave::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 195px;
            bottom: 0px;
            left: 0;
            background: url('../../assets/images/wave.svg');
            opacity: .2;
            // background-position: 300px
            animation: wave-reverse 8s linear infinite;

        }

        .login-admin__wave::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 195px;
            bottom: 0px;
            left: 0;
            background: url('../../assets/images/wave.svg');
            opacity: .8;
            animation-delay: -4s;
            // background-position: 600px
            animation: wave 16s linear infinite;
        }

        @keyframes wave {
            0% {
                background-position: 0;
            }

            100% {
                background-position: 1280px;
            }
        }

        @keyframes wave-reverse {
            0% {
                background-position: 1280px;
            }

            100% {
                background-position: 0;
            }
        }

        .login-admin__form {
            display: flex;
            flex-direction: column;
            // margin-top: 210px;
            // padding: 16px;
            background-color: $colorMoreLightViolet;
            width: 350px;
            z-index: 1;
            height: 500px;
            border-radius: $borderRadiusL;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

            .login-admin__form--left {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 16px;
                background-color: #4158D0;
                // background-image: linear-gradient(43deg, #3E187A 0%, #C850C0 46%, #FFCC70 100%);
                background-image: linear-gradient(43deg, #C850C0 46%, #3E187A 100%);
                height: 20%;
                width: 100%;
                border-radius: $borderRadiusL $borderRadiusL 0px 0px;

                .login-admin__form--left-child {
                    background-color: transparent;
                    border-radius: $borderRadiusL;
                }
            }

            .login-admin__form--right {
                display: flex;
                flex-direction: column;
                align-items: center;
                // gap: $gapL;
                background-color: $colorWhite;
                height: 80%;
                width: 100%;
                border-radius: 0px 0px $borderRadiusL $borderRadiusL;
                padding: 12px;
                overflow-y: hidden;

                .login-admin__form--right-logo {
                    width: 80px;
                    height: 50px;
                    border-radius: $borderRadiusFull;
                }

                .login-admin__form--right-login {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    gap: $gapL;
                    opacity: 1;
                    transition: all 0.5s ease-out;
                    padding-top: 12px;

                    &.hide {
                        visibility: hidden;
                        height: 0px;
                        opacity: 0;
                    }

                    .login-admin__form--right-login-header {
                        text-align: center;

                        span {
                            font-size: $textFontSizeXL;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }

                    }

                    .login-admin__form--right-login-input {
                        display: flex;
                        flex-direction: column;
                        gap: $gapL;
                        width: 100%;

                    }

                    .login-admin__form--right-login-line {
                        position: relative;
                        width: 70%;
                        height: 2px;
                        background-color: $colorMoreLightGray;
                        border-radius: $borderRadiusL;
                        margin: 0 auto;

                        .login-admin__form--right-login-line-other {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: $colorLightGray;
                            background-color: $colorWhite; // Đặt màu nền để chữ không bị che bởi đường thẳng
                            padding: 0 8px; // Tạo khoảng cách giữa chữ và viền nền
                        }
                    }

                    .login-admin__form--right-login-sign-up {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: $gapXS;

                        .login-admin__form--right-login-sign-up-label {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightL;
                            color: $colorBlack;

                            &.high-light {
                                font-weight: $fontWeightXL;
                                color: $colorDarkViolet;
                            }
                        }
                    }
                }

                .login-admin__form--right-sign-up {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    gap: $gapL;
                    opacity: 1;
                    transition: all 0.5s ease-out;

                    &.hide {
                        visibility: hidden;
                        height: 0px;
                        opacity: 0;
                    }

                    .login-admin__form--right-sign-up-header {
                        text-align: center;

                        span {
                            font-size: $textFontSizeXL;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }

                    }

                    .login-admin__form--right-sign-up-input {
                        display: flex;
                        flex-direction: column;
                        gap: $gapL;
                        width: 100%;
                    }

                    .login-admin__form--right-sign-up-line {
                        position: relative;
                        width: 70%;
                        height: 2px;
                        background-color: $colorMoreLightGray;
                        border-radius: $borderRadiusL;
                        margin: 0 auto;

                        .login-admin__form--right-sign-up-line-other {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: $colorLightGray;
                            background-color: $colorWhite; // Đặt màu nền để chữ không bị che bởi đường thẳng
                            padding: 0 8px; // Tạo khoảng cách giữa chữ và viền nền
                        }
                    }

                    .login-admin__form--right-sign-up-sign-up {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: $gapXS;

                        .login-admin__form--right-sign-up-sign-up-label {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightL;
                            color: $colorBlack;

                            &.high-light {
                                font-weight: $fontWeightXL;
                                color: $colorDarkViolet;
                            }
                        }
                    }
                }
            }

        }
    }
}

.login-admin__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    height: fit-content;
    width: 100%;
    background-color: $colorWhite;
    border: 1px solid $colorDarkViolet;
    border-radius: $borderRadiusFull;
    text-align: center;
    transition: all 0.3s ease-out;

    span {
        font-size: $textFontSizeM;
        font-weight: $fontWeightXL;
        color: $colorDarkViolet;
    }

    &:hover {
        background-color: $colorDarkViolet;

        span {
            color: $colorWhite;
        }
    }
}

// Modal nhập mã OTP
.login-admin__card--information-otp {
    display: flex;
    flex-direction: column;
    gap: $gapM;

    .login-admin__card--information-otp-header {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 12px 0px 0px 0px;

        span {
            font-size: $textFontSizeXL;
            font-weight: $fontWeightXL;
            color: $colorBlack;
        }
    }

    .login-admin__card--information-otp-label {
        text-align: center;
        font-size: $textFontSizeM;
        font-weight: $fontWeightL;
        color: $colorBlack;

        .bold {
            font-weight: $fontWeightXL;
        }

        .high-light {
            color: $colorOrange;
        }

        .click-able {
            cursor: pointer;
        }
    }

    .login-admin__card--information-forgot-password {
        font-size: $textFontSizeM;
        font-weight: $fontWeightL;
        color: $colorBlack;
        text-align: center;

        &.high-light {
            color: $colorOrange;
            font-weight: $fontWeightXL;
        }

        &.clickable {
            cursor: pointer;
        }

    }


    .login-admin__card--information-otp-digit {
        display: flex;
        gap: $gapL;
        justify-content: center;
        align-items: center;

        .login-admin__card--information-otp-digit-number {
            width: 55px;
            height: 55px;
            font-size: $textFontSizeXL;
            text-align: center;
            border: 3px solid $colorMoreLightGray;
            border-radius: 8px;
            outline: none;
            transition: all 0.3s ease;

            &:focus {
                border-color: $colorViolet;
            }
        }
    }

    .login-admin__card--information-otp-resend {}
}

.login-admin__card--information {
    display: flex;
    flex-direction: column;
    gap: $gapM;
    padding: 12px 12px 0px 12px;

    .login-admin__card--information-header {
        display: flex;
        gap: $gapL;
        align-items: center;
        justify-content: center;
        padding-bottom: 4px;

        .login-admin__card--information-header-child {
            display: flex;
            align-items: center;
            gap: $gapS;
            transition: all 1s ease;

            .login-admin__card--information-header-child-step-number {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                background-color: transparent;
                border: 2px solid $colorMoreLightGray;
                border-radius: $borderRadiusFull;
                transition: all 1s ease;
                cursor: pointer;

                span {
                    font-size: $textFontSizeL;
                    font-weight: $fontWeightXL;
                    color: $colorLightGray;
                }
            }

            .login-admin__card--information-header-child-step-title {
                display: flex;
                flex-direction: column;
                gap: $gapXS;
                transition: all 1s ease;

                .login-admin__card--information-header-child-step-title-vietnam {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightXL;
                    color: $colorMoreLightGray;
                }

                .login-admin__card--information-header-child-step-title-english {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorMoreLightGray;
                }


            }

            .login-admin__card--information-header-child-step-line {
                height: 2px;
                width: 100px;
                background-color: $colorMoreLightGray;
            }

            &.activated {
                .login-admin__card--information-header-child-step-number {
                    background-color: $colorOrange;
                    border: 2px solid $colorOrange;

                    span {
                        color: $colorWhite;
                    }
                }

                .login-admin__card--information-header-child-step-title {
                    display: flex;

                    .login-admin__card--information-header-child-step-title-vietnam {
                        color: $colorBlack;
                    }

                    .login-admin__card--information-header-child-step-title-english {
                        color: $colorLightGray;
                    }


                }
            }
        }

    }

    .login-admin__card--information-body {
        display: flex;
        flex-direction: column;
        gap: $gapL;
        width: 100%;
        // background-color: black;

        .login-admin__card--information-body-child {
            display: flex;
            width: 100%;
            gap: $gapL;

            .login-admin__card--information-body-child-input {
                width: 100%;
            }
        }

        
        .login-admin__card--information-process-bar {
            display: flex;
            position: relative;
            width: 100%;
            height: 8px;
            border-radius: $borderRadiusFull;
            background-color: $colorExtraLightGray;

            .login-admin__card--information-password-process-bar-child {
                position: absolute;
                top: 0;
                left: 0;
                background-color: $colorBlack;
                width: 0%;
                height: 100%;
                transition: all 0.5s ease-out;
                border-radius: $borderRadiusFull;

                &.empty {
                    background-color: $colorMoreLightGray;
                    width: 0%;
                }

                &.week {
                    background-color: $colorRed;
                    width: 25%;

                }

                &.fear {
                    background-color: $colorOrange;
                    width: 50%;
                }

                &.good {
                    background-color: $colorLime;
                    width: 75%;
                }

                &.strong {
                    background-color: $colorGreen;
                    width: 100%;
                }
            }
        }
    }

    .login-admin__card--information-process-bar {
        display: flex;
        position: relative;
        width: 100%;
        height: 8px;
        border-radius: $borderRadiusFull;
        background-color: $colorExtraLightGray;

        .login-admin__card--information-process-bar-child {
            position: absolute;
            top: 0;
            left: 0;
            background-color: $colorBlack;
            width: 0%;
            height: 100%;
            transition: all 0.5s ease-out;
            border-radius: $borderRadiusFull;

            &.empty {
                background-color: $colorMoreLightGray;
                width: 0%;
            }

            &.week {
                background-color: $colorRed;
                width: 25%;

            }

            &.fear {
                background-color: $colorOrange;
                width: 50%;
            }

            &.good {
                background-color: $colorLime;
                width: 75%;
            }

            &.strong {
                background-color: $colorGreen;
                width: 100%;
            }
        }
    }

    .login-admin__card--information-label {
        display: flex;
        justify-content: space-between;

        .login-admin__card--information-label-child {
            font-size: $textFontSizeM;
            font: $fontWeightL;
            color: $colorWhite;

            &.empty {
                color: $colorLightGray;
            }

            &.week {
                color: $colorRed;
            }

            &.fear {
                color: $colorOrange;
            }

            &.good {
                color: $colorLime;
            }

            &.strong {
                color: $colorGreen;
            }
        }
    }

    .login-admin__card--information-condition-required {
        display: flex;
        flex-direction: column;
        gap: $gapS;

        .login-admin__card--information-condition-required-child {
            display: flex;
            gap: $gapXS;
            align-items: center;
            transition: all 0.5s ease-out;

            span {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorLightGray;
            }

            &.checked {

                span {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorGreen;
                }
            }
        }
    }
}