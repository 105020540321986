@import "../../index.scss";

.content-create-order {
  // max-width: 61%;
  max-width: 630px;
}

.div-filter {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 100%;

  .header-filter {
    font-size: 10;
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1000;
    margin-top: 35px;
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    padding: 5px;
  }
}

.btn-create-order {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  margin: auto auto auto 15px;
}

.div-select-app {
  display: flex;
  flex-direction: column;
  border: 1px solid #9f9f9f;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.div-select-time {
  cursor: pointer;
  max-width: 100px;
  font-size: 14px;
  font-family: "Roboto";
  color: #ababab;
  margin: 0px 10px 0px 0px;
  border: 1px solid #b6b6b6;
  border-radius: 5px;
  padding: 5px;
}

.date-picker-select-time {}

.select_horizontal_no_thumbnail {
  .item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 1px solid #9f9f9f;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
}

.select_horizontal_thumbnail {
  img {
    width: 30px;
  }

  .item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 1px solid #9f9f9f;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
}

.multi_select_horizontal_thumbnail {
  img {
    width: 30px;
  }

  .item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 1px solid #9f9f9f;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
}

.single_select_horizontal_thumbnail {
  img {
    width: 30px;
  }

  .item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 1px solid #9f9f9f;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
}

.multi_select_count_sofa,
.multi_select_count_ac {

  // width: 40%;
  img {
    width: 30px;
  }

  .item {
    display: flex;
    box-sizing: border-box;
    border: 1px solid #9f9f9f;
    width: 200px;
    margin: 0px 10px 10px 0px;
    height: 115px;
    border-radius: 8px;
    padding: 3px;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .container-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px 10px 0px 10px;
      width: 100%;
    }

    .counter-optional {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 65px;
    }
  }
}

.item-selected {
  cursor: unset !important;
  border: 1px solid $color1;
  background-color: $color1;
  color: #fff;
}

.date-of-week {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .div-active-loop-week {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    p {
      margin: 0 10px 0px 0px;
    }
  }
}

.content-date-of-week {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px 0px;

  .item-date-of-week {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    display: flex;
    width: 50px;
    height: 45px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
}

.content-month-schedule {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px 0px;

  .item {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    display: flex;
    width: 100px;
    height: 45px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
}

Select {
  width: 300px;
}

.div-list-address-default {
  max-height: 300px;
  overflow-y: scroll;
  position: absolute;
  margin: 50px 0px;
  z-index: 1000;
  background-color: #ffff;
  width: 80%;
  border: 1px solid #ffff;

  .div-item-address {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #d1d1d1;

    .div-name-address {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
  }
}

.div-list-address-search {
  max-height: 100%;
  overflow-y: scroll;

  position: absolute;
  margin: 50px 0px;
  z-index: 1000;
  background-color: #ffff;
  width: 80%;
  border: 1px solid #ffff;

  .div-item-address {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #d1d1d1;

    .div-name-address {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
  }
}

.list-code-promotion-available {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 8px 0px;

  .item {
    cursor: pointer;
    margin-right: 20px;
    border: 1px solid #b6b6b6;
    height: 60px;
    width: 200px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    justify-content: center;
    padding-left: 8px;

    .title {
      font-weight: 500;
      font-size: 16px;
    }

    .short-description {}
  }

  // .item-selected {
  //   cursor: unset !important;
  //   border: 1px solid $color1;
  //   background-color: $color1;
  //   color: #fff;
  // }
}

.fee-order {
  max-width: 300px;

  .initial-fee {
    margin: 3px 0px !important;
  }

  .service-fee {
    margin: 3px 0px !important;
  }

  .event-promotion {
    margin: 3px 0px !important;

    p {
      color: $colorDiscount;
    }
  }

  .code-promotion {
    margin: 3px 0px !important;

    p {
      color: $colorDiscount;
    }
  }

  .final-fee {
    margin: 3px 0px !important;

    p {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.date-detail-schedule {
  margin: 20px;

  .div-title-month {
    font-size: 20px;
    font-weight: 500;
  }

  .div-month {
    margin: 0px 0px 20px 0px;

    .column-week {
      .div-title-week {
        font-size: 17px;
        font-weight: 500;
      }

      .empty-date {
        margin: 40px 0px 0px 0px;
      }

      .div-date-schedule {
        cursor: pointer;
        text-align: center;
        padding: 5px;
        margin: 10px 0px 0px 0px;
        border: 1px solid #fff;
      }

      .div-date-selected {
        cursor: unset !important;
        border: 1px solid $color1;
        background-color: $color1;
        color: #fff;
        border-radius: 50%;
      }
    }
  }
}

.manage-order {
  display: flex;
  flex-direction: column;
  gap: $gapL;
  padding-bottom: 16px;

  .manage-order__header {
    span {
      font-weight: $fontWeightXL;
      font-size: $textFontSize3XL;
      color: $colorBlack;
    }
  }

  .manage-order__search {
    display: flex;
    gap: $gapM;
    .manage-order__search-field { 
      width: 450px;
    }
  }


  .manage-order__filter-content {
    display: flex;
    gap: $gapM;

    .manage-order__filter-content--search-field {
      width: 400px;
      overflow: hidden;
    }

    .manage-order__filter-content--tab {
      background-color: $colorMoreLightGray;
      display: flex;
      gap: $gapS;
      align-items: center;
      justify-content: center;
      padding: 9px 10px;
      border-radius: $borderRadiusL;
      cursor: pointer;
      color: $colorBlack;
      transition: 0.5s all ease-out;


      .manage-order__filter-content--tab-label {
        font-weight: $fontWeightL;
        font-size: $textFontSizeM;
      }

      .manage-order__filter-content--tab-number {
        background-color: $colorWhite;
        padding: 2px 4px;
        min-width: 20px;
        border-radius: $borderRadiusFull;
        color: $colorBlack;
        text-align: center;
      }

      &.selected {
        background-color: $colorViolet;
        color: $colorWhite;
      }
    }
  }
}