@import "../../../../@theme/style.scss";

.refferend-list-affiliate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: 100%;
    background-color: #4158D0;
    background: linear-gradient(120deg, #C584FD 20%, #db94ff 50%, #7B97FE 70%, #6CD4FE 100%);

    .refferend-list-affiliate__content {
        display: flex;
        padding: 16px 16px 32px 16px;
        gap: $gapL;
        width: 100%;
        height: fit-content;

        .refferend-list-affiliate__content--left {
            display: flex;
            flex-direction: column;
            gap: $gapL;
            width: 20%;
            height: fit-content;

            .refferend-list-affiliate__content--left-card {
                // position: relative;
                display: flex;
                flex-direction: column;
                padding: 12px;
                background-color: $colorWhite;
                border-radius: $borderRadiusL;

                &.no-padding {
                    padding: 0px;
                }

                &.border-left-highlight {
                    border-left: 5px solid;
                }

                &.border-blue {
                    border-color: $colorBlue;
                }

                &.border-green {
                    border-color: $colorGreen;
                }

                &.border-red {
                    border-color: $colorRed;
                }

                .refferend-list-affiliate__content--left-card-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 2px solid $colorExtraLightGray;
                    padding: 12px 12px;

                    span {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }
                }

                .refferend-list-affiliate__content--left-card-body {
                    display: flex;
                    flex-direction: column;
                    padding: 12px;
                    gap: $gapM;

                    .refferend-list-affiliate__content--left-card-body-image {
                        width: 100%;
                        height: 100px;
                        background-image: linear-gradient(43deg, $colorViolet 0%, #E19FCE 46%, #e462bf 100%);
                        border-radius: $borderRadiusL;
                    }

                    .refferend-list-affiliate__content--left-card-body-steps {
                        // position: relative;
                        display: flex;
                        // align-items: center;
                        gap: $gapS;
                        min-height: 48px;
                        margin-bottom: -12px;
                        // background-color: $colorExtraLightYellow;

                        .refferend-list-affiliate__content--left-card-body-steps-count {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .refferend-list-affiliate__content--left-card-body-steps-count-circle {
                                // width: 28px;
                                // height: 28px;
                                padding: 6px 12px;
                                border-radius: $borderRadiusFull;
                                background-color: $colorViolet;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: white;
                                font-size: $textFontSizeM;
                            }

                            .refferend-list-affiliate__content--left-card-body-steps-count-line {
                                width: 2px;
                                height: 100%;
                                background-color: $colorMoreLightViolet;
                            }
                        }


                        .refferend-list-affiliate__content--left-card-body-steps-step {
                            width: 85%;

                            span {
                                font-weight: $fontWeightL;
                                font-size: $textFontSizeM;
                                color: $colorBlack;
                            }

                        }
                    }

                    .refferend-list-affiliate__content--left-card-body-code {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        gap: $gapXS;
                        // margin-top: 12px;

                        .refferend-list-affiliate__content--left-card-body-code-content {
                            display: flex;
                            justify-content: space-between;
                            padding: 12px 24px;
                            width: 90%;
                            background-color: $colorMoreLightViolet;
                            border-radius: $borderRadiusFull;

                            .refferend-list-affiliate__content--left-card-body-code-content-label {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                            }

                            .refferend-list-affiliate__content--left-card-body-code-content-copy {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorViolet;
                                cursor: pointer;
                                transition: all 0.3s ease-out;

                                &.not-allow-save {
                                    color: $colorLightGray;
                                    cursor: not-allowed;

                                    &:hover {
                                        color: $colorLightGray;
                                    }
                                }

                                &:hover {
                                    color: $colorDarkViolet;
                                }
                            }

                            .refferend-list-affiliate__content--left-card-body-code-content-input {
                                background-color: transparent;
                                outline: none;
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                                width: 70%;
                            }
                        }

                        .refferend-list-affiliate__content--left-card-body-code-random {
                            display: flex;
                            justify-content: center;
                            padding: 12px 24px;
                            width: 90%;
                            background-color: $colorViolet;
                            border-radius: $borderRadiusFull;
                            cursor: pointer;
                            transition: all 0.3s ease-out;

                            &:hover {
                                background-color: $colorDarkViolet;
                            }

                            .refferend-list-affiliate__content--left-card-body-code-random-label {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorWhite;
                            }
                        }

                        .refferend-list-affiliate__content--left-card-body-code-describe {
                            // background-color: $colorBlack;
                            width: 100%;
                            height: 100%;
                            padding: 0px 24px;

                            span {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorLightGray;
                                opacity: 0.8;
                                font-style: italic;
                            }

                        }
                    }
                }

                .refferend-list-affiliate__content--left-card-statistic {
                    display: flex;
                    flex-direction: column;

                    .refferend-list-affiliate__content--left-card-statistic-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 4px 8px;
                        border-radius: $borderRadiusL;
                        color: $colorWhite;
                        font-size: $textFontSize3XL;

                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                        }

                        &.green {
                            background-color: $colorGreen;
                        }

                        &.red {
                            background-color: $colorRed;
                        }

                        &.blue {
                            background-color: $colorExtraLightBlue;
                            color: $colorBlue;
                        }
                    }

                    .refferend-list-affiliate__content--left-card-statistic-content {
                        display: flex;
                        flex-direction: column;
                        // background-color: $colorExtraLightGreen;
                        padding: 12px;

                        .refferend-list-affiliate__content--left-card-statistic-content-value {

                            .refferend-list-affiliate__content--left-card-statistic-content-value-number {
                                font-size: $textFontSize3XL;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                                padding-right: 4px;
                            }

                            .refferend-list-affiliate__content--left-card-statistic-content-value-unit {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorBlack;
                            }
                        }

                        .refferend-list-affiliate__content--left-card-statistic-content-previous {
                            span {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorLightGray;
                            }
                        }
                    }
                }

                .refferend-list-affiliate__content--left-card-content {
                    display: flex;
                    justify-content: space-between;

                    .refferend-list-affiliate__content--left-card-content-describe {
                        display: flex;
                        flex-direction: column;
                        gap: $gapXS;

                        .refferend-list-affiliate__content--left-card-content-describe-label {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }

                        .refferend-list-affiliate__content--left-card-content-describe-value {
                            font-size: $textFontSize3XL;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;

                            .unit {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorBlack;
                            }
                        }
                    }

                    .refferend-list-affiliate__content--left-card-content-icon {
                        padding: 8px;
                        font-size: $textFontSize3XL;
                        border-radius: $borderRadiusFull;
                        width: fit-content;
                        height: fit-content;

                        &.blue {
                            color: $colorBlue;
                            background-color: $colorExtraLightBlue;
                        }


                        &.red {
                            color: $colorRed;
                            background-color: $colorExtraLightRed;
                        }

                        &.yellow {
                            color: $colorYellow;
                            background-color: $colorExtraLightYellow;
                        }


                        &.green {
                            color: $colorGreen;
                            background-color: $colorExtraLightGreen;
                        }
                    }
                }

                .refferend-list-affiliate__content--left-card-previous {
                    span {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightL;
                        color: $colorLightGray;
                    }
                }
            }

            .refferend-list-affiliate__content--left-card-share-link {
                display: none;
                flex-direction: column;
                background-color: $colorWhite ;
                border-radius: $borderRadiusL;

                .refferend-list-affiliate__content--left-card-share-link-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 2px solid $colorExtraLightGray;
                    padding: 12px 12px;

                    span {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }
                }

                .refferend-list-affiliate__content--left-card-share-link-content {
                    display: flex;
                    flex-direction: column;
                    gap: $gapL;
                    padding: 12px;

                    .refferend-list-affiliate__content--left-card-share-link-content-highlight {
                        display: flex;
                        gap: $gapXS;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100px;
                        background-color: $colorMoreLightViolet;
                        border-radius: $borderRadiusL;

                        .refferend-list-affiliate__content--left-card-share-link-content-highlight-title {
                            font-size: $textFontSize3XL;
                            font-weight: $fontWeightXL;
                            color: $colorOrange;
                        }

                        .refferend-list-affiliate__content--left-card-share-link-content-highlight-sub-title {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }
                    }

                    .refferend-list-affiliate__content--left-card-share-link-content-share {
                        display: flex;
                        flex-direction: column;

                        .refferend-list-affiliate__content--left-card-share-link-content-share-header {
                            display: flex;
                            align-items: center;

                            span {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                            }



                        }

                        .refferend-list-affiliate__content--left-card-share-link-content-share-link {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .refferend-list-affiliate__content--left-card-share-link-content-share-link-url {
                                max-width: 200px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                cursor: pointer;
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorLightGray;
                            }

                            .refferend-list-affiliate__content--left-card-share-link-content-share-link-url-copy {
                                padding: 1px 8px;
                                border-radius: $borderRadiusFull;
                                border: 2px solid $colorViolet;
                                color: $colorViolet;
                                transition: 0.3s all ease-out;
                                cursor: pointer;

                                &:hover {
                                    background-color: $colorViolet;
                                    color: $colorWhite
                                }
                            }
                        }

                    }
                }
            }

            .refferend-list-affiliate__content--left-icon {
                padding: 8px;
                font-size: $textFontSize3XL;
                border-radius: $borderRadiusFull;

                &.blue {
                    color: $colorBlue;
                    background-color: $colorExtraLightBlue;
                }


                &.red {
                    color: $colorRed;
                    background-color: $colorExtraLightRed;
                }

                &.yellow {
                    color: $colorYellow;
                    background-color: $colorExtraLightYellow;
                }


                &.green {
                    color: $colorGreen;
                    background-color: $colorExtraLightGreen;
                }
            }

            .refferend-list-affiliate__content--left-share {
                display: flex;
                flex-direction: column;
                background-color: $colorWhite ;
                border-radius: $borderRadiusL;

                .refferend-list-affiliate__content--left-share-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 2px solid $colorExtraLightGray;
                    padding: 12px 12px;

                    span {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }
                }

                .refferend-list-affiliate__content--left-share-content {
                    display: flex;
                    flex-direction: column;
                    gap: $gapL;
                    padding: 12px;

                    .refferend-list-affiliate__content--left-share-content-highlight {
                        display: flex;
                        gap: $gapXS;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100px;
                        background-color: $colorMoreLightViolet;
                        border-radius: $borderRadiusL;

                        .refferend-list-affiliate__content--left-share-content-highlight-title {
                            font-size: $textFontSize3XL;
                            font-weight: $fontWeightXL;
                            color: $colorOrange;
                        }

                        .refferend-list-affiliate__content--left-share-content-highlight-sub-title {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }
                    }

                    .refferend-list-affiliate__content--left-share-content-share {
                        display: flex;
                        flex-direction: column;

                        .refferend-list-affiliate__content--left-share-content-share-header {
                            display: flex;
                            align-items: center;

                            span {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                            }



                        }

                        .refferend-list-affiliate__content--left-share-content-share-link {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .refferend-list-affiliate__content--left-share-content-share-link-url {
                                max-width: 150px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                cursor: pointer;
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorLightGray;
                            }

                            .refferend-list-affiliate__content--left-share-content-share-link-url-copy {
                                padding: 1px 8px;
                                border-radius: $borderRadiusFull;
                                border: 2px solid $colorViolet;
                                color: $colorViolet;
                                transition: 0.3s all ease-out;
                                cursor: pointer;

                                &:hover {
                                    background-color: $colorViolet;
                                    color: $colorWhite
                                }
                            }
                        }

                    }
                }
            }

            .refferend-list-affiliate__content--left-exit {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: rgba(222, 216, 216, 0.5);
                border-radius: $borderRadiusL;
                padding: 16px;
                cursor: pointer;
                transition: all 1.5s ease;

                &:hover {
                    background-color: $colorWhite;
                }

                .refferend-list-affiliate__content--left-exit-title {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightXL;
                    color: $colorBlack
                }

                .refferend-list-affiliate__content--left-exit-icon {
                    font-size: $textFontSizeL;
                    font-weight: $fontWeightL;
                    color: $colorBlack;
                }

            }

        }

        .refferend-list-affiliate__content--middle {
            display: flex;
            flex-direction: column;
            gap: $gapL;
            min-width: 55%;
            max-width: 55%;
            height: fit-content;

            .refferend-list-affiliate__content--middle-content {
                border-radius: $borderRadiusL;
                background-color: $colorWhite;

                .refferend-list-affiliate__content--middle-content-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 2px solid $colorExtraLightGray;
                    padding: 12px;

                    span {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }

                    .refferend-list-affiliate__content--middle-content-header-options {
                        display: flex;
                        gap: $gapS;

                        .refferend-list-affiliate__content--middle-content-header-options-tab {
                            padding: 6px 8px;
                            background-color: $colorMoreLightGray;
                            color: $colorBlack;
                            border-radius: $borderRadiusL;
                            cursor: pointer;
                            transition: 0.3s all ease;
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            text-align: center;

                            &.activated {
                                background-color: $colorViolet;
                                color: $colorWhite;
                            }
                        }
                    }
                }

                .refferend-list-affiliate__content--middle-content-history-receiving {
                    display: flex;
                    padding: 16px;

                    .refferend-list-affiliate__content--middle-content-history-receiving-left {
                        width: 20%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding-top: 1px;

                        .refferend-list-affiliate__content--middle-content-history-receiving-left-time {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                        }

                        .refferend-list-affiliate__content--middle-content-history-receiving-left-date {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightL;
                            color: $colorGray;
                            opacity: $opacityM;
                            text-transform: capitalize;
                        }
                    }

                    .refferend-list-affiliate__content--middle-content-history-receiving-middle {
                        width: 10%;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: -32px;

                        .refferend-list-affiliate__content--middle-content-history-receiving-middle-icon {
                            padding: 8px 8px;
                            width: fit-content;
                            height: fit-content;
                            border-radius: $borderRadiusFull;

                            &.up {
                                background-color: $colorExtraLightGreen;
                            }

                            &.down {
                                background-color: $colorExtraLightRed;
                            }

                            &.setting {
                                background-color: $colorExtraLightBlue;
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-history-receiving-middle-line {
                            width: 2px;
                            height: 100%;
                            background-color: $colorExtraLightGray;
                        }
                    }

                    .refferend-list-affiliate__content--middle-content-history-receiving-right {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 75%;

                        .refferend-list-affiliate__content--middle-content-history-receiving-right-top {
                            display: flex;
                            justify-content: space-between;
                            border: 2px solid $colorExtraLightGray;
                            padding: 4px 12px;
                            border-radius: $borderRadiusL;
                            margin-left: 8px;
                            width: 100%;
                            min-height: 55px;

                            .refferend-list-affiliate__content--middle-content-history-receiving-right-top-title {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                            }

                            .refferend-list-affiliate__content--middle-content-history-receiving-right-top-money {
                                display: flex;
                                align-items: center;
                                gap: $gapXS;

                                .refferend-list-affiliate__content--middle-content-history-receiving-right-top-money-title {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorGray;
                                    opacity: $opacityM;
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: end;

                                // padding-top: 4px;
                                .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction-number {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    padding: 4px 8px;
                                    width: fit-content;
                                    border-radius: $borderRadiusL;
                                    white-space: nowrap;

                                    &.up {
                                        color: $colorGreen;
                                        background-color: $colorExtraLightGreen;
                                    }

                                    &.down {
                                        color: $colorRed;
                                        background-color: $colorExtraLightRed;
                                    }
                                }

                                .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction-detail {
                                    display: flex;
                                    align-items: center;
                                    gap: $gapXS;

                                    .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction-detail-label {
                                        display: flex;
                                        align-items: center;
                                        gap: $gapXS;
                                        transition: all 0.3s ease;
                                        text-decoration: none;
                                        cursor: pointer;

                                        &:hover {
                                            color: $colorBlue
                                        }
                                    }

                                }
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom {
                            display: grid;
                            grid-template-columns: repeat(4, minmax(0, 1fr));
                            justify-content: space-between;
                            // align-items: center;
                            border: 2px solid $colorExtraLightGray;
                            border-top: none;
                            width: 95%;
                            padding: 4px 12px;
                            // border-radius-t: $borderRadiusL;
                            border-bottom-left-radius: $borderRadiusL;
                            border-bottom-right-radius: $borderRadiusL;
                            margin-left: 8px;
                            // min-height: 50px;
                            height: 40px;
                            transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
                            overflow: hidden;
                            /* Ensures content is hidden when height is 0 */
                            opacity: 1;
                            /* Default opacity */

                            .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom-item {
                                display: flex;
                                align-items: center;

                                .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom-item-label {
                                    margin-right: 4px;
                                }

                                .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom-item-link {
                                    color: $colorBlue;
                                }
                            }

                            &.hidden {
                                height: 0;
                                opacity: 0;
                                padding: 0 12px;
                            }
                        }
                    }

                    .refferend-list-affiliate__content--middle-content-history-receiving-not-found {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        align-items: center;
                        justify-content: center;

                        img {
                            height: 100px;
                            width: 100%;
                        }

                        .refferend-list-affiliate__content--middle-content-history-receiving-not-found-label {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                            font-style: italic;
                        }
                    }
                }

                .refferend-list-affiliate__content--middle-content-deposit-withdraw {
                    display: flex;
                    padding: 16px;

                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-left {
                        width: 20%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding-top: 1px;
                        background-color: yello;

                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-left-time {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                        }

                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-left-date {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightL;
                            color: $colorGray;
                            opacity: $opacityM;
                            text-transform: capitalize;
                        }
                    }

                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-middle {
                        width: 10%;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: -32px;

                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-middle-icon {
                            padding: 8px 8px;
                            width: fit-content;
                            height: fit-content;
                            border-radius: $borderRadiusFull;

                            &.up {
                                background-color: $colorExtraLightGreen;
                            }

                            &.down {
                                background-color: $colorExtraLightRed;
                            }

                            &.setting {
                                background-color: $colorExtraLightBlue;
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-middle-line {
                            width: 2px;
                            height: 100%;
                            background-color: $colorExtraLightGray;
                        }
                    }

                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-right {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 75%;

                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top {
                            display: flex;
                            justify-content: space-between;
                            border: 2px solid $colorExtraLightGray;
                            padding: 4px 12px;
                            border-radius: $borderRadiusL;
                            margin-left: 8px;
                            width: 100%;
                            min-height: 55px;

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-title {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                            }

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-money {
                                display: flex;
                                align-items: center;
                                gap: $gapXS;

                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-money-title {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorGray;
                                    opacity: $opacityM;
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-transiction {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: end;

                                // padding-top: 4px;
                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-transiction-number {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    padding: 4px 8px;
                                    width: fit-content;
                                    border-radius: $borderRadiusL;
                                    white-space: nowrap;

                                    &.up {
                                        color: $colorGreen;
                                        background-color: $colorExtraLightGreen;
                                    }

                                    &.down {
                                        color: $colorRed;
                                        background-color: $colorExtraLightRed;
                                    }
                                }

                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-transiction-detail {
                                    display: flex;
                                    align-items: center;
                                    gap: $gapXS;

                                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-transiction-detail-label {
                                        display: flex;
                                        align-items: center;
                                        gap: $gapXS;
                                        transition: all 0.3s ease;
                                        text-decoration: none;
                                        cursor: pointer;

                                        &:hover {
                                            color: $colorBlue
                                        }
                                    }

                                }
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-bottom {
                            display: grid;
                            grid-template-columns: repeat(4, minmax(0, 1fr));
                            justify-content: space-between;
                            // align-items: center;
                            border: 2px solid $colorExtraLightGray;
                            border-top: none;
                            width: 95%;
                            padding: 4px 12px;
                            // border-radius-t: $borderRadiusL;
                            border-bottom-left-radius: $borderRadiusL;
                            border-bottom-right-radius: $borderRadiusL;
                            margin-left: 8px;
                            // min-height: 50px;
                            height: 40px;
                            transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
                            overflow: hidden;
                            /* Ensures content is hidden when height is 0 */
                            opacity: 1;
                            /* Default opacity */

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-bottom-item {
                                display: flex;
                                align-items: center;

                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-bottom-item-label {
                                    margin-right: 4px;
                                }

                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-bottom-item-link {
                                    color: $colorBlue;
                                }
                            }

                            &.hidden {
                                height: 0;
                                opacity: 0;
                                padding: 0 12px;
                            }
                        }
                    }
                }

                .refferend-list-affiliate__content--middle-content-refferend-list {
                    display: flex;
                    padding: 16px;

                    .refferend-list-affiliate__content--middle-content-refferend-list-container {
                        display: flex;
                        position: relative;
                        flex-direction: column;
                        width: 100%;
                        overflow-x: hidden;
                        height: fit-content;
                        border: 1px solid $colorViolet;
                        border-radius: $borderRadiusL;


                        .refferend-list-affiliate__content--middle-content-refferend-list-container-persons {
                            display: flex;
                            align-items: center;
                            overflow-x: hidden;
                            white-space: nowrap;
                            max-width: 100%;
                            padding: 12px 0px;
                            border-radius: $borderRadiusL;

                            .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person {
                                display: flex;
                                border-right: 2px solid $colorMoreLightGray;
                                padding: 0px 24px;
                                flex-direction: column;
                                align-items: center;
                                gap: $gapS;

                                &.first-item {
                                    border-left: 2px solid $colorMoreLightGray;
                                }


                                .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-value {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 70px;
                                    height: 60px;
                                    border-radius: $borderRadiusL;
                                    border: 1px solid $colorViolet;
                                    background-color: $colorExtraLightViolet;

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-value-unit {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        gap: $gapXS;

                                        .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-value-unit-label {
                                            font-weight: $fontWeightL;
                                            font-size: $textFontSizeM;
                                            color: $colorGray;
                                            font-style: italic;
                                            padding: 0px 4px;
                                            border: 1px solid $colorGray;
                                            border-radius: $borderRadiusFull;
                                        }

                                        .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-value-unit-number {
                                            font-weight: $fontWeightXL;
                                            font-size: $textFontSize3XL;
                                            color: $colorBlack;
                                        }
                                    }
                                }

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-info {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 60px;
                                    height: 60px;
                                    border-radius: $borderRadiusFull;
                                    background-color: $colorViolet;

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-info-name {
                                        font-weight: $fontWeightXL;
                                        font-size: $textFontSize3XL;
                                        color: $colorWhite;

                                        &.adding {
                                            color: $colorLightGray;
                                        }
                                    }


                                    &.adding {
                                        background-color: $colorExtraLightViolet;
                                        border: 1px solid $colorViolet;
                                    }
                                }

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-counting {
                                    display: flex;
                                    flex-direction: column;
                                    gap: $gapXS;
                                    align-items: center;

                                    span {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightL;
                                        color: $colorBlack;
                                        white-space: nowrap;
                                    }

                                    &.newest-person {
                                        span {
                                            font-weight: $fontWeightXL;
                                        }
                                    }

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-counting-phone {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightL;
                                        color: $colorLightGray;
                                    }
                                }
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-refferend-list-container-navigate {
                            position: absolute;
                            top: 20%;

                            &.left {
                                left: 0;
                            }


                            &.right {
                                right: 0;
                            }

                            &.hidden {
                                display: none;
                            }

                            .refferend-list-affiliate__content--middle-content-refferend-list-container-navigate-icon {
                                font-size: $textFontSize3XL;
                                background-color: $colorMoreLightGray;
                                color: $colorBlack;
                                padding: 16px 4px;

                                &.left {
                                    left: 0;
                                    border-top-right-radius: $borderRadiusL;
                                    border-bottom-right-radius: $borderRadiusL;
                                }

                                &.right {
                                    right: 0;
                                    border-top-left-radius: $borderRadiusL;
                                    border-bottom-left-radius: $borderRadiusL;
                                }

                                .rotate-left {
                                    transform: rotate(180deg);
                                }

                            }
                        }

                        .refferend-list-affiliate__content--middle-content-refferend-list-container-note {
                            display: flex;
                            gap: $gapL;
                            width: 100%;
                            padding: 16px 32px;
                            background-color: $colorExtraLightViolet;
                            border-bottom-left-radius: $borderRadiusL;
                            border-bottom-right-radius: $borderRadiusL;

                            .refferend-list-affiliate__content--middle-content-refferend-list-container-note-child {
                                display: flex;
                                flex-direction: column;
                                gap: $gapXS;
                                width: 60%;
                                // background-color: $colorRed;

                                span {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorBlack;
                                }

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-note-child-image {
                                    width: 100%;
                                    height: 180px;
                                }

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-note-child-header {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                }

                                .high-light {
                                    color: $colorOrange;
                                    font-weight: $fontWeightXL;
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-refferend-list-container-note-image {
                                width: 40%;

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-note-image-child {
                                    width: 100%;
                                    height: 180px;
                                }
                            }
                        }
                    }

                }

                .refferend-list-affiliate__content--middle-content-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .refferend-list-affiliate__content--middle-content-pagination {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 16px;
                    padding-right: 16px;
                }


            }
        }

        .refferend-list-affiliate__content--right {
            width: 25%;
            height: fit-content;
            display: flex;
            flex-direction: column;
            gap: $gapL;

            .refferend-list-affiliate__content--right-info {
                display: flex;
                justify-content: space-between;
                background-color: #4158D0;
                background-image: linear-gradient(43deg, #77ADFF 20%, #C582FD 100%);
                border-radius: $borderRadiusL;
                padding: 12px;

                .refferend-list-affiliate__content--right-info-content {
                    display: flex;
                    align-items: center;
                    gap: $gapM;

                    .refferend-list-affiliate__content--right-info-content-avatar {
                        width: 40px;
                        height: 40px;
                        background-color: $colorWhite;
                        border-radius: $borderRadiusFull;
                        border: 2px solid $colorWhite
                    }

                    .refferend-list-affiliate__content--right-info-content-name {
                        display: flex;
                        flex-direction: column;
                        gap: $gapXS;

                        .refferend-list-affiliate__content--right-info-content-name-title {
                            font-size: $textFontSizeL;
                            font-weight: $fontWeightXL;
                            color: $colorWhite;
                        }

                        .refferend-list-affiliate__content--right-info-content-name-sub {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorExtraLightGray;
                            opacity: 0.8;
                        }
                    }

                }

                .refferend-list-affiliate__content--right-info-action {
                    display: flex;
                    align-items: center;
                    gap: $gapS;

                    .refferend-list-affiliate__content--right-info-action-child {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 4px;
                        border-radius: $borderRadiusFull;
                        background-color: $colorWhite;
                        cursor: pointer;
                    }

                }
            }

            .refferend-list-affiliate__content--right-bank {
                display: flex;
                flex-direction: column;
                background-color: #4158D0;
                background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #feb53d 100%);
                border-radius: $borderRadiusL;

                .refferend-list-affiliate__content--right-bank-content {
                    // height: 75%;
                    // width: 100%;
                    // background-color: yellow;
                    display: flex;
                    flex-direction: column;
                    gap: $gapL;
                    padding: 16px;


                    .refferend-list-affiliate__content--right-bank-content-top {
                        display: flex;
                        justify-content: space-between;

                        .refferend-list-affiliate__content--right-bank-content-top-circle {
                            position: relative;

                            .refferend-list-affiliate__content--right-bank-content-top-circle-shape {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 20px;
                                height: 20px;
                                border-radius: $borderRadiusFull;

                                &.orange {
                                    left: 14px;
                                    z-index: 0;
                                    background-color: $colorOrange;
                                    opacity: 0.85;
                                }

                                &.red {
                                    background-color: $colorRed;
                                }
                            }

                            .refferend-list-affiliate__content--right-bank-content-top-circle-name {
                                position: absolute;
                                top: 0;
                                left: 20px;
                            }
                        }

                        .refferend-list-affiliate__content--right-bank-content-top-location {
                            display: flex;
                            align-items: center;
                            gap: $gapXS;

                            .refferend-list-affiliate__content--right-bank-content-top-location-icon {
                                font-size: $textFontSize2XL;
                                color: $colorWhite;
                            }

                            .refferend-list-affiliate__content--right-bank-content-top-location-label {
                                font-weight: $fontWeightXL;
                                font-size: $textFontSizeM;
                                color: $colorWhite;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .refferend-list-affiliate__content--right-bank-content-middle {
                        display: flex;
                        justify-content: space-between;

                        .refferend-list-affiliate__content--right-bank-content-middle-info {
                            display: flex;
                            flex-direction: column;
                            gap: $gapS;

                            .refferend-list-affiliate__content--right-bank-content-middle-info-label {
                                font-weight: $fontWeightL;
                                font-size: $textFontSizeM;
                                color: $colorMoreLightGray;
                            }

                            .refferend-list-affiliate__content--right-bank-content-middle-info-value {
                                font-weight: $fontWeightXL;
                                font-size: $textFontSize3XL;
                                color: $colorWhite;
                            }
                        }
                    }
                }

                .refferend-list-affiliate__content--right-bank-bottom {
                    display: flex;
                    justify-content: space-between;
                    padding: 0px 16px 16px 16px;
                    align-items: center;
                    // border-top: 1px solid $colorLightGray;

                    .refferend-list-affiliate__content--right-bank-bottom-name {
                        font-weight: $fontWeightXL;
                        font-size: $textFontSizeM;
                        color: $colorYellow;
                    }

                    .refferend-list-affiliate__content--right-bank-bottom-icon {
                        display: flex;
                        gap: $gapS;
                        align-items: center;

                        .refferend-list-affiliate__content--right-bank-bottom-icon-child {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            border-radius: $borderRadiusFull;
                            font-size: $textFontSize3XL;

                            .refferend-list-affiliate__content--right-bank-bottom-icon-child-icon {
                                padding: 4px;
                                background-color: $colorWhite;
                                border-radius: $borderRadiusFull;
                                font-size: $textFontSizeXL;
                                font-weight: $fontWeightL;
                                color: $colorWhite;
                                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                                cursor: pointer;
                            }

                            .refferend-list-affiliate__content--right-bank-bottom-icon-child-label {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorWhite;
                            }
                        }
                    }

                }
            }

            .refferend-list-affiliate__content--right-share {
                display: flex;
                flex-direction: column;
                background-color: $colorWhite ;
                border-radius: $borderRadiusL;

                .refferend-list-affiliate__content--right-share-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 2px solid $colorExtraLightGray;
                    padding: 12px 12px;

                    span {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }
                }

                .refferend-list-affiliate__content--right-share-content {
                    display: flex;
                    flex-direction: column;
                    gap: $gapL;
                    padding: 12px;

                    .refferend-list-affiliate__content--right-share-content-highlight {
                        display: flex;
                        gap: $gapXS;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100px;
                        background-color: $colorMoreLightViolet;
                        border-radius: $borderRadiusL;

                        .refferend-list-affiliate__content--right-share-content-highlight-title {
                            font-size: $textFontSize3XL;
                            font-weight: $fontWeightXL;
                            color: $colorOrange;
                        }

                        .refferend-list-affiliate__content--right-share-content-highlight-sub-title {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }
                    }

                    .refferend-list-affiliate__content--right-share-content-share {
                        display: flex;
                        flex-direction: column;

                        .refferend-list-affiliate__content--right-share-content-share-header {
                            display: flex;
                            align-items: center;

                            span {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                            }



                        }

                        .refferend-list-affiliate__content--right-share-content-share-link {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .refferend-list-affiliate__content--right-share-content-share-link-url {
                                max-width: 200px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                cursor: pointer;
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorLightGray;
                            }

                            .refferend-list-affiliate__content--right-share-content-share-link-url-copy {
                                padding: 1px 8px;
                                border-radius: $borderRadiusFull;
                                border: 2px solid $colorViolet;
                                color: $colorViolet;
                                transition: 0.3s all ease-out;
                                cursor: pointer;

                                &:hover {
                                    background-color: $colorViolet;
                                    color: $colorWhite
                                }
                            }
                        }

                    }
                }
            }

            .refferend-list-affiliate__content--right-referral-policy {
                position: relative;
                height: fit-content;
                width: 100%;

                .refferend-list-affiliate__content--right-referral-policy-image {
                    height: fit-content;
                    width: 100%;
                }

                .refferend-list-affiliate__content--right-referral-policy-content {
                    display: flex;
                    flex-direction: column;
                    top: 50%;
                    left: 70%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    width: 50%;
                    gap: $gapS;
                    // background-color: yellow;

                    .refferend-list-affiliate__content--right-referral-policy-content-header {
                        font-weight: $fontWeightXL;
                        font-size: $textFontSizeM;
                        color: $colorWhite;
                    }

                    .refferend-list-affiliate__content--right-referral-policy-content-describe {
                        font-weight: $fontWeightL;
                        font-size: $textFontSizeM;
                        color: $colorMoreLightGray
                    }

                    .refferend-list-affiliate__content--right-referral-policy-content-read-more {
                        display: flex;
                        gap: $gapXS;
                        align-items: center;
                        font-weight: $fontWeightXL;
                        font-size: $textFontSizeM;
                        color: $colorOrange;
                        padding: 6px;
                        background-color: $colorExtraLightOrange;
                        width: fit-content;
                        border-radius: $borderRadiusL;
                        cursor: pointer;
                        transition: 0.3s all ease-out;

                        &:hover {
                            background-color: $colorOrange;
                            color: $colorWhite;
                        }
                    }
                }
            }

            .refferend-list-affiliate__content--right-statistic {
                display: flex;
                flex-direction: column;
                padding: 12px;
                background-color: $colorWhite;
                border-radius: $borderRadiusL;

                &.no-padding {
                    padding: 0px;
                }

                &.border-left-highlight {
                    border-left: 5px solid;
                }

                &.border-blue {
                    border-color: $colorBlue;
                }

                &.border-green {
                    border-color: $colorGreen;
                }

                &.border-red {
                    border-color: $colorRed;
                }

                .refferend-list-affiliate__content--right-statistic-content {
                    display: flex;
                    justify-content: space-between;

                    .refferend-list-affiliate__content--right-statistic-content-describe {
                        display: flex;
                        flex-direction: column;
                        gap: $gapXS;

                        .refferend-list-affiliate__content--right-statistic-content-describe-label {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }

                        .refferend-list-affiliate__content--right-statistic-content-describe-value {
                            font-size: $textFontSize3XL;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;

                            .unit {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorBlack;
                            }
                        }
                    }

                    .refferend-list-affiliate__content--right-statistic-content-icon {
                        padding: 8px;
                        font-size: $textFontSize3XL;
                        border-radius: $borderRadiusFull;
                        width: fit-content;
                        height: fit-content;

                        &.blue {
                            color: $colorBlue;
                            background-color: $colorExtraLightBlue;
                        }


                        &.red {
                            color: $colorRed;
                            background-color: $colorExtraLightRed;
                        }

                        &.yellow {
                            color: $colorYellow;
                            background-color: $colorExtraLightYellow;
                        }


                        &.green {
                            color: $colorGreen;
                            background-color: $colorExtraLightGreen;
                        }
                    }
                }

                .refferend-list-affiliate__content--right-statistic-previous {
                    span {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightL;
                        color: $colorLightGray;
                    }
                }
            }
        }
    }

    .refferend-list-affiliate__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        // background-color: black;
        background-image: url('../../../../assets/images/footer_wave.png');
        width: 100%;
        height: 35vh;

        .refferend-list-affiliate__footer--container {
            display: flex;
            flex-direction: column;
            // align-items: center;
            justify-content: center;
            width: 70%;
            height: 100%;

            .refferend-list-affiliate__footer--container-information {
                display: flex;
                align-items: center;
                padding: 0px;

                .refferend-list-affiliate__footer--container-information-item {
                    width: 25%;
                    height: fit-content;

                    img {
                        width: 100px;
                        height: fit-content;
                    }

                    span {
                        font-size: $textFontSize2XL;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;

                        .high-light {
                            color: $colorViolet
                        }
                    }
                }
            }

            .refferend-list-affiliate__footer--container-copy-right {
                display: flex;
                align-items: center;
                padding: 16px;
                gap: $gapL;
                border-top: 1px solid $colorBlack;

                .refferend-list-affiliate__footer--container-copy-right-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30%;
                    height: fit-content;

                    img {
                        width: 150px;
                        height: fit-content;
                        cursor: pointer;
                    }

                    span {
                        font-size: $textFontSize2XL;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;

                        .high-light {
                            color: $colorViolet
                        }
                    }
                }
            }
        }

    }
}

.refferend-list-affiliate__withdrawal {
    display: flex;
    flex-direction: column;
    gap: $gapL;
    padding: 16px;

    .refferend-list-affiliate__withdrawal--suggest {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $gapL;

        .refferend-list-affiliate__withdrawal--suggest-money {
            padding: 8px;
            border-radius: $borderRadiusL;
            border: 1px solid $colorViolet;
            cursor: pointer;
            transition: all 0.3s ease-out;
            background-color: $colorWhite;
            color: $colorBlack;
            font-weight: $fontWeightXL;

            &:hover {
                background-color: $colorViolet;
                border-color: $colorMoreLightViolet;
                color: $colorOrange;
            }
        }
    }

    .refferend-list-affiliate__withdrawal--notice {
        display: flex;
        flex-direction: column;
        gap: $gapM;


        .refferend-list-affiliate__withdrawal--notice-header {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
        }

        .refferend-list-affiliate__withdrawal--notice-info {
            display: flex;
            align-items: center;
            gap: $gapL;

            .refferend-list-affiliate__withdrawal--notice-info-icon {
                padding: 8px;
                background-color: $colorExtraLightOrange;
                border-radius: $borderRadiusFull;
                font-size: $textFontSizeL;
                color: $colorOrange;
            }

            .refferend-list-affiliate__withdrawal--notice-info-text {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorBlack;
            }

        }
    }
}

.refferend-list-affiliate__bank {
    display: flex;
    flex-direction: column;
    gap: $gapL;
}

.refferend-list-affiliate__policy {
    display: flex;
    flex-direction: column;
    gap: $gapL;

    .refferend-list-affiliate__policy--content {
        display: flex;
        flex-direction: column;
        gap: $gapS;

        .refferend-list-affiliate__policy--content-header {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
        }

        .refferend-list-affiliate__policy--content-body {
            display: flex;
            position: relative;

            .refferend-list-affiliate__policy--content-body-dot {
                position: absolute;
                top: 2px;
                left: 0;
                width: 4px;
                height: 4px;
                background-color: $colorBlack;
                border-radius: $borderRadiusFull;
            }

            .refferend-list-affiliate__policy--content-body-text {
                margin-left: 20px;
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorBlack;

                .high-light {
                    color: $colorOrange
                }
            }
        }
    }
}

.refferend-list-affiliate__share-link {
    display: flex;
    flex-direction: column;
    gap: $gapL;

    .refferend-list-affiliate__share-link--qr-code {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $gapL;
        padding: 20px 8px;
        border: 2px solid $colorMoreLightGray;
        border-radius: $borderRadiusL;

        .refferend-list-affiliate__share-link--qr-code-child {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: $gapM;
            position: relative;
            background-color: $colorMoreLightViolet;
            border-radius: $borderRadiusL;
            padding: 12px;

            .refferend-list-affiliate__share-link--qr-code-child-download {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 4px;
                background-color: $colorMoreLightGray;
                border-radius: $borderRadiusFull;
                cursor: pointer;
            }

            .refferend-list-affiliate__share-link--qr-code-child-container {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $colorLightViolet;
                border: 8px solid $colorBlack;
                border-radius: $borderRadiusL;
                width: 200px;
                height: 200px;

                .refferend-list-affiliate__share-link--qr-code-child-container-rouded-border-vertical {
                    position: absolute;
                    background-color: $colorLightViolet;
                    width: 130px;
                    height: 200px;
                }


                .refferend-list-affiliate__share-link--qr-code-child-container-rouded-border-horizontal {
                    position: absolute;
                    background-color: $colorLightViolet;
                    width: 200px;
                    height: 130px;
                }

                .refferend-list-affiliate__share-link--qr-code-child-container-rouded-border-qr-code {
                    position: absolute;
                }


            }

            .refferend-list-affiliate__share-link--qr-code-child-title {
                font-size: 1.1rem;
                font-weight: $fontWeight2XL;
                color: $colorBlack;
                text-transform: uppercase;
                font-family: 'Work Sans', sans-serif;
                padding-top: 12px;
            }
        }
    }

    .refferend-list-affiliate__share-link--example {
        display: flex;
        flex-direction: column;
        border: 1px solid $colorMoreLightGray;
        border-radius: $borderRadiusL;

        .refferend-list-affiliate__share-link--example-header {
            display: flex;
            justify-content: space-between;

            .refferend-list-affiliate__share-link--example-header-left {
                background-color: $colorExtraLightOrange;
                border-top-left-radius: $borderRadiusL;
                border-bottom-right-radius: $borderRadiusL;
                padding: 8px;
                font-size: $textFontSizeM;
                font-weight: $fontWeightXL;
                color: $colorBlack;
            }

            .refferend-list-affiliate__share-link--example-header-right {
                display: flex;
                align-items: center;
                gap: $gapXS;
                padding: 8px;
                color: $colorOrange;
            }
        }

        .refferend-list-affiliate__share-link--example-body {
            display: flex;
            flex-direction: column;
            padding: 8px;

            span {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorBlack;

                .high-light {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorViolet;
                }
            }

        }
    }

    .refferend-list-affiliate__share-link--social {
        display: flex;
        gap: $gapL;

        .refferend-list-affiliate__share-link--social-child {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: $gapS;

            .refferend-list-affiliate__share-link--social-child-circle {
                background-color: $colorMoreLightGray;
                border-radius: $borderRadiusFull;
                padding: 8px;
            }

            .refferend-list-affiliate__share-link--social-child-label {
                font-size: $textFontSizeM;
                font-weight: $fontWeightXL;
                color: $colorBlack;
            }
        }
    }
}

.refferend-list-affiliate__information {
    display: flex;
    flex-direction: column;
    gap: $gapL;
    padding: 12px;
    max-height: fit-content;

    .refferend-list-affiliate__information--child {
        display: flex;
        gap: $gapL;
        width: 100%;

        .refferend-list-affiliate__information--child-item {
            width: 100%;
        }
    }

    .refferend-list-affiliate__information--forgot-password {
        display: flex;
        justify-content: space-between;

        span {
            font-size: $textFontSizeM;
            font-weight: $fontWeightL;
            color: $colorViolet;
            cursor: pointer;
        }

        &.hide {
            opacity: 0;
            height: 0;
            width: 0;
        }
    }

    .refferend-list-affiliate__information--update-password {
        display: flex;
        flex-direction: column;
        gap: $gapL;
        border-top: 2px solid $colorMoreLightGray;
        padding-top: 16px;
        opacity: 1;
        max-height: 500px;
        transition: max-height 1s ease, opacity 1.5s ease;

        &.hide {
            max-height: 0px;
            width: 0px;
            opacity: 0;
            overflow: hidden;
            visibility: hidden;
            position: absolute;
        }

        .refferend-list-affiliate__change-password--new-password {
            display: flex;
            flex-direction: column;
            gap: $gapL;
            // border-top: 1px solid $colorMoreLightGray;
            // padding-top: 16px;

            .login-affiliate__card--information-password-process-bar {
                display: flex;
                position: relative;
                width: 100%;
                height: 8px;
                border-radius: $borderRadiusFull;
                background-color: $colorExtraLightGray;

                .login-affiliate__card--information-password-process-bar-child {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $colorBlack;
                    width: 0%;
                    height: 100%;
                    transition: all 0.5s ease-out;
                    border-radius: $borderRadiusFull;

                    &.empty {
                        background-color: $colorMoreLightGray;
                        width: 0%;
                    }

                    &.week {
                        background-color: $colorRed;
                        width: 25%;

                    }

                    &.fear {
                        background-color: $colorOrange;
                        width: 50%;
                    }

                    &.good {
                        background-color: $colorLime;
                        width: 75%;
                    }

                    &.strong {
                        background-color: $colorGreen;
                        width: 100%;
                    }
                }
            }

            .login-affiliate__card--information-password-label {
                display: flex;
                justify-content: space-between;

                .login-affiliate__card--information-password-label-child {
                    font-size: $textFontSizeM;
                    font: $fontWeightL;
                    color: $colorWhite;

                    &.empty {
                        color: $colorLightGray;
                    }

                    &.week {
                        color: $colorRed;
                    }

                    &.fear {
                        color: $colorOrange;
                    }

                    &.good {
                        color: $colorLime;
                    }

                    &.strong {
                        color: $colorGreen;
                    }
                }
            }

            .login-affiliate__card--information-password-condition-required {
                display: flex;
                flex-direction: column;
                gap: $gapS;

                .login-affiliate__card--information-password-condition-required-child {
                    display: flex;
                    gap: $gapXS;
                    align-items: center;
                    transition: all 0.5s ease-out;

                    span {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightL;
                        color: $colorLightGray;
                    }

                    &.checked {

                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightL;
                            color: $colorGreen;
                        }
                    }
                }
            }
        }
    }

}

.refferend-list-affiliate__change-password {
    display: flex;
    flex-direction: column;
    gap: $gapL;

    .refferend-list-affiliate__change-password--title {
        font-size: $textFontSizeM;
        font-weight: $fontWeightL;
        color: $colorLightGray;
    }

    .refferend-list-affiliate__change-password-otp-digit {
        display: flex;
        gap: $gapL;
        // justify-content: center;
        align-items: center;

        .refferend-list-affiliate__change-password-otp-digit-number {
            width: 40px;
            height: 40px;
            font-size: $textFontSizeM;
            text-align: center;
            border: 2px solid $colorMoreLightGray;
            border-radius: $borderRadiusL;
            outline: none;
            transition: all 0.3s ease;

            &:focus {
                border-color: $colorViolet;
            }
        }
    }

    .refferend-list-affiliate__change-password--otp-verify {
        display: flex;
        align-items: center;
        gap: $gapXS;

        .refferend-list-affiliate__change-password--otp-verify-label {
            font-size: $textFontSizeM;
            font-weight: $fontWeightL;
            color: $colorBlack;

            &.verify {
                color: $colorGreen;
            }
        }

    }

    .refferend-list-affiliate__change-password--new-password {
        display: flex;
        flex-direction: column;
        gap: $gapL;
        // border-top: 1px solid $colorMoreLightGray;
        // padding-top: 16px;

        .login-affiliate__card--information-password-process-bar {
            display: flex;
            position: relative;
            width: 100%;
            height: 8px;
            border-radius: $borderRadiusFull;
            background-color: $colorExtraLightGray;

            .login-affiliate__card--information-password-process-bar-child {
                position: absolute;
                top: 0;
                left: 0;
                background-color: $colorBlack;
                width: 0%;
                height: 100%;
                transition: all 0.5s ease-out;
                border-radius: $borderRadiusFull;

                &.empty {
                    background-color: $colorMoreLightGray;
                    width: 0%;
                }

                &.week {
                    background-color: $colorRed;
                    width: 25%;

                }

                &.fear {
                    background-color: $colorOrange;
                    width: 50%;
                }

                &.good {
                    background-color: $colorLime;
                    width: 75%;
                }

                &.strong {
                    background-color: $colorGreen;
                    width: 100%;
                }
            }
        }

        .login-affiliate__card--information-password-label {
            display: flex;
            justify-content: space-between;

            .login-affiliate__card--information-password-label-child {
                font-size: $textFontSizeM;
                font: $fontWeightL;
                color: $colorWhite;

                &.empty {
                    color: $colorLightGray;
                }

                &.week {
                    color: $colorRed;
                }

                &.fear {
                    color: $colorOrange;
                }

                &.good {
                    color: $colorLime;
                }

                &.strong {
                    color: $colorGreen;
                }
            }
        }

        .login-affiliate__card--information-password-condition-required {
            display: flex;
            flex-direction: column;
            gap: $gapS;

            .login-affiliate__card--information-password-condition-required-child {
                display: flex;
                gap: $gapXS;
                align-items: center;
                transition: all 0.5s ease-out;

                span {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorLightGray;
                }

                &.checked {

                    span {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightL;
                        color: $colorGreen;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .refferend-list-affiliate {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        width: 100%;

        .refferend-list-affiliate__content {
            display: flex;
            padding: 16px;
            gap: $gapL;
            width: 100%;
            height: fit-content;

            .refferend-list-affiliate__content--left {
                display: flex;
                flex-direction: column;
                gap: $gapL;
                width: 25%;
                height: fit-content;

                .refferend-list-affiliate__content--left-card {
                    // position: relative;
                    display: flex;
                    flex-direction: column;
                    padding: 12px;
                    background-color: $colorWhite;
                    border-radius: $borderRadiusL;

                    &.no-padding {
                        padding: 0px;
                    }

                    &.border-left-highlight {
                        border-left: 5px solid;
                    }

                    &.border-blue {
                        border-color: $colorBlue;
                    }

                    &.border-green {
                        border-color: $colorGreen;
                    }

                    &.border-red {
                        border-color: $colorRed;
                    }

                    .refferend-list-affiliate__content--left-card-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 2px solid $colorExtraLightGray;
                        padding: 12px 12px;

                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }
                    }

                    .refferend-list-affiliate__content--left-card-body {
                        display: flex;
                        flex-direction: column;
                        padding: 12px;
                        gap: $gapM;

                        .refferend-list-affiliate__content--left-card-body-image {
                            width: 100%;
                            height: 100px;
                            background-color: #D16FCC;
                            border-radius: $borderRadiusL;
                        }

                        .refferend-list-affiliate__content--left-card-body-steps {
                            // position: relative;
                            display: flex;
                            // align-items: center;
                            gap: $gapS;
                            min-height: 48px;
                            margin-bottom: -12px;
                            // background-color: $colorExtraLightYellow;

                            .refferend-list-affiliate__content--left-card-body-steps-count {
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                .refferend-list-affiliate__content--left-card-body-steps-count-circle {
                                    // width: 28px;
                                    // height: 28px;
                                    padding: 6px 12px;
                                    border-radius: $borderRadiusFull;
                                    background-color: $colorViolet;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: white;
                                    font-size: $textFontSizeM;
                                }

                                .refferend-list-affiliate__content--left-card-body-steps-count-line {
                                    width: 2px;
                                    height: 100%;
                                    background-color: $colorMoreLightViolet;
                                }
                            }


                            .refferend-list-affiliate__content--left-card-body-steps-step {
                                width: 85%;

                                span {
                                    font-weight: $fontWeightL;
                                    font-size: $textFontSizeM;
                                    color: $colorBlack;
                                }

                            }
                        }

                        .refferend-list-affiliate__content--left-card-body-code {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            gap: $gapXS;
                            // margin-top: 12px;

                            .refferend-list-affiliate__content--left-card-body-code-content {
                                display: flex;
                                justify-content: space-between;
                                padding: 12px 24px;
                                width: 90%;
                                background-color: $colorMoreLightViolet;
                                border-radius: $borderRadiusFull;

                                .refferend-list-affiliate__content--left-card-body-code-content-label {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                }

                                .refferend-list-affiliate__content--left-card-body-code-content-copy {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorViolet;
                                    cursor: pointer;
                                    transition: all 0.3s ease-out;

                                    &.not-allow-save {
                                        color: $colorLightGray;
                                        cursor: not-allowed;

                                        &:hover {
                                            color: $colorLightGray;
                                        }
                                    }

                                    &:hover {
                                        color: $colorDarkViolet;
                                    }
                                }

                                .refferend-list-affiliate__content--left-card-body-code-content-input {
                                    background-color: transparent;
                                    outline: none;
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                    width: 70%;
                                }
                            }

                            .refferend-list-affiliate__content--left-card-body-code-random {
                                display: flex;
                                justify-content: center;
                                padding: 12px 24px;
                                width: 90%;
                                background-color: $colorViolet;
                                border-radius: $borderRadiusFull;
                                cursor: pointer;
                                transition: all 0.3s ease-out;

                                &:hover {
                                    background-color: $colorDarkViolet;
                                }

                                .refferend-list-affiliate__content--left-card-body-code-random-label {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorWhite;
                                }
                            }
                        }
                    }

                    .refferend-list-affiliate__content--left-card-statistic {
                        display: flex;
                        flex-direction: column;

                        .refferend-list-affiliate__content--left-card-statistic-header {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 4px 8px;
                            border-radius: $borderRadiusL;
                            color: $colorWhite;
                            font-size: $textFontSize3XL;

                            span {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                            }

                            &.green {
                                background-color: $colorGreen;
                            }

                            &.red {
                                background-color: $colorRed;
                            }

                            &.blue {
                                background-color: $colorExtraLightBlue;
                                color: $colorBlue;
                            }
                        }

                        .refferend-list-affiliate__content--left-card-statistic-content {
                            display: flex;
                            flex-direction: column;
                            // background-color: $colorExtraLightGreen;
                            padding: 12px;

                            .refferend-list-affiliate__content--left-card-statistic-content-value {

                                .refferend-list-affiliate__content--left-card-statistic-content-value-number {
                                    font-size: $textFontSize3XL;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                    padding-right: 4px;
                                }

                                .refferend-list-affiliate__content--left-card-statistic-content-value-unit {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorBlack;
                                }
                            }

                            .refferend-list-affiliate__content--left-card-statistic-content-previous {
                                span {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorLightGray;
                                }
                            }
                        }
                    }

                    .refferend-list-affiliate__content--left-card-content {
                        display: flex;
                        justify-content: space-between;

                        .refferend-list-affiliate__content--left-card-content-describe {
                            display: flex;
                            flex-direction: column;
                            gap: $gapXS;

                            .refferend-list-affiliate__content--left-card-content-describe-label {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                            }

                            .refferend-list-affiliate__content--left-card-content-describe-value {
                                font-size: $textFontSize3XL;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;

                                .unit {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorBlack;
                                }
                            }
                        }

                        .refferend-list-affiliate__content--left-card-content-icon {
                            padding: 8px;
                            font-size: $textFontSize3XL;
                            border-radius: $borderRadiusFull;
                            width: fit-content;
                            height: fit-content;

                            &.blue {
                                color: $colorBlue;
                                background-color: $colorExtraLightBlue;
                            }


                            &.red {
                                color: $colorRed;
                                background-color: $colorExtraLightRed;
                            }

                            &.yellow {
                                color: $colorYellow;
                                background-color: $colorExtraLightYellow;
                            }


                            &.green {
                                color: $colorGreen;
                                background-color: $colorExtraLightGreen;
                            }
                        }
                    }

                    .refferend-list-affiliate__content--left-card-previous {
                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightL;
                            color: $colorLightGray;
                        }
                    }

                    .refferend-list-affiliate__content--left-card-share-link {
                        display: flex;
                        flex-direction: column;
                        background-color: $colorWhite ;
                        border-radius: $borderRadiusL;

                        .refferend-list-affiliate__content--left-card-share-link-header {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-bottom: 2px solid $colorExtraLightGray;
                            padding: 12px 12px;

                            span {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                            }
                        }

                        .refferend-list-affiliate__content--left-card-share-link-content {
                            display: flex;
                            flex-direction: column;
                            gap: $gapL;
                            padding: 12px;

                            .refferend-list-affiliate__content--left-card-share-link-content-highlight {
                                display: flex;
                                gap: $gapXS;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                height: 100px;
                                background-color: $colorMoreLightViolet;
                                border-radius: $borderRadiusL;

                                .refferend-list-affiliate__content--left-card-share-link-content-highlight-title {
                                    font-size: $textFontSize3XL;
                                    font-weight: $fontWeightXL;
                                    color: $colorOrange;
                                }

                                .refferend-list-affiliate__content--left-card-share-link-content-highlight-sub-title {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                }
                            }

                            .refferend-list-affiliate__content--left-card-share-link-content-share {
                                display: flex;
                                flex-direction: column;

                                .refferend-list-affiliate__content--left-card-share-link-content-share-header {
                                    display: flex;
                                    align-items: center;

                                    span {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightXL;
                                        color: $colorBlack;
                                    }



                                }

                                .refferend-list-affiliate__content--left-card-share-link-content-share-link {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    .refferend-list-affiliate__content--left-card-share-link-content-share-link-url {
                                        max-width: 200px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        cursor: pointer;
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightL;
                                        color: $colorLightGray;
                                    }

                                    .refferend-list-affiliate__content--left-card-share-link-content-share-link-url-copy {
                                        padding: 1px 8px;
                                        border-radius: $borderRadiusFull;
                                        border: 2px solid $colorViolet;
                                        color: $colorViolet;
                                        transition: 0.3s all ease-out;
                                        cursor: pointer;

                                        &:hover {
                                            background-color: $colorViolet;
                                            color: $colorWhite
                                        }
                                    }
                                }

                            }
                        }
                    }
                }

                .refferend-list-affiliate__content--left-card-share-link {
                    display: none;
                    flex-direction: column;
                    background-color: $colorWhite ;
                    border-radius: $borderRadiusL;

                    .refferend-list-affiliate__content--left-card-share-link-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 2px solid $colorExtraLightGray;
                        padding: 12px 12px;

                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }
                    }

                    .refferend-list-affiliate__content--left-card-share-link-content {
                        display: flex;
                        flex-direction: column;
                        gap: $gapL;
                        padding: 12px;

                        .refferend-list-affiliate__content--left-card-share-link-content-highlight {
                            display: flex;
                            gap: $gapXS;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100px;
                            background-color: $colorMoreLightViolet;
                            border-radius: $borderRadiusL;

                            .refferend-list-affiliate__content--left-card-share-link-content-highlight-title {
                                font-size: $textFontSize3XL;
                                font-weight: $fontWeightXL;
                                color: $colorOrange;
                            }

                            .refferend-list-affiliate__content--left-card-share-link-content-highlight-sub-title {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                            }
                        }

                        .refferend-list-affiliate__content--left-card-share-link-content-share {
                            display: flex;
                            flex-direction: column;

                            .refferend-list-affiliate__content--left-card-share-link-content-share-header {
                                display: flex;
                                align-items: center;

                                span {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                }



                            }

                            .refferend-list-affiliate__content--left-card-share-link-content-share-link {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .refferend-list-affiliate__content--left-card-share-link-content-share-link-url {
                                    max-width: 200px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    cursor: pointer;
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorLightGray;
                                }

                                .refferend-list-affiliate__content--left-card-share-link-content-share-link-url-copy {
                                    padding: 1px 8px;
                                    border-radius: $borderRadiusFull;
                                    border: 2px solid $colorViolet;
                                    color: $colorViolet;
                                    transition: 0.3s all ease-out;
                                    cursor: pointer;

                                    &:hover {
                                        background-color: $colorViolet;
                                        color: $colorWhite
                                    }
                                }
                            }

                        }
                    }
                }

                .refferend-list-affiliate__content--left-icon {
                    padding: 8px;
                    font-size: $textFontSize3XL;
                    border-radius: $borderRadiusFull;

                    &.blue {
                        color: $colorBlue;
                        background-color: $colorExtraLightBlue;
                    }


                    &.red {
                        color: $colorRed;
                        background-color: $colorExtraLightRed;
                    }

                    &.yellow {
                        color: $colorYellow;
                        background-color: $colorExtraLightYellow;
                    }


                    &.green {
                        color: $colorGreen;
                        background-color: $colorExtraLightGreen;
                    }
                }

            }

            .refferend-list-affiliate__content--middle {
                display: flex;
                flex-direction: column;
                gap: $gapL;
                width: 50%;
                max-width: 55%;
                height: fit-content;

                .refferend-list-affiliate__content--middle-content {
                    border-radius: $borderRadiusL;
                    background-color: $colorWhite;

                    .refferend-list-affiliate__content--middle-content-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 2px solid $colorExtraLightGray;
                        padding: 12px;

                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }

                        .refferend-list-affiliate__content--middle-content-header-options {
                            display: flex;
                            gap: $gapS;

                            .refferend-list-affiliate__content--middle-content-header-options-tab {
                                padding: 6px 8px;
                                background-color: $colorMoreLightGray;
                                color: $colorBlack;
                                border-radius: $borderRadiusL;
                                cursor: pointer;
                                transition: 0.3s all ease;

                                &.activated {
                                    background-color: $colorViolet;
                                    color: $colorWhite;
                                }
                            }
                        }
                    }

                    .refferend-list-affiliate__content--middle-content-history-receiving {
                        display: flex;
                        padding: 16px;

                        .refferend-list-affiliate__content--middle-content-history-receiving-left {
                            width: 20%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-top: 1px;

                            .refferend-list-affiliate__content--middle-content-history-receiving-left-time {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                            }

                            .refferend-list-affiliate__content--middle-content-history-receiving-left-date {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorGray;
                                opacity: $opacityM;
                                text-transform: capitalize;
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-history-receiving-middle {
                            width: 10%;
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: -32px;

                            .refferend-list-affiliate__content--middle-content-history-receiving-middle-icon {
                                padding: 8px 8px;
                                width: fit-content;
                                height: fit-content;
                                border-radius: $borderRadiusFull;

                                &.up {
                                    background-color: $colorExtraLightGreen;
                                }

                                &.down {
                                    background-color: $colorExtraLightRed;
                                }

                                &.setting {
                                    background-color: $colorExtraLightBlue;
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-history-receiving-middle-line {
                                width: 2px;
                                height: 100%;
                                background-color: $colorExtraLightGray;
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-history-receiving-right {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 75%;

                            .refferend-list-affiliate__content--middle-content-history-receiving-right-top {
                                display: flex;
                                justify-content: space-between;
                                border: 2px solid $colorExtraLightGray;
                                padding: 4px 12px;
                                border-radius: $borderRadiusL;
                                margin-left: 8px;
                                width: 100%;
                                min-height: 55px;

                                .refferend-list-affiliate__content--middle-content-history-receiving-right-top-title {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                }

                                .refferend-list-affiliate__content--middle-content-history-receiving-right-top-money {
                                    display: flex;
                                    align-items: center;
                                    gap: $gapXS;

                                    .refferend-list-affiliate__content--middle-content-history-receiving-right-top-money-title {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightL;
                                        color: $colorGray;
                                        opacity: $opacityM;
                                    }
                                }

                                .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    align-items: end;

                                    // padding-top: 4px;
                                    .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction-number {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightXL;
                                        padding: 4px 8px;
                                        width: fit-content;
                                        border-radius: $borderRadiusL;
                                        white-space: nowrap;

                                        &.up {
                                            color: $colorGreen;
                                            background-color: $colorExtraLightGreen;
                                        }

                                        &.down {
                                            color: $colorRed;
                                            background-color: $colorExtraLightRed;
                                        }
                                    }

                                    .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction-detail {
                                        display: flex;
                                        align-items: center;
                                        gap: $gapXS;

                                        .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction-detail-label {
                                            display: flex;
                                            align-items: center;
                                            gap: $gapXS;
                                            transition: all 0.3s ease;
                                            text-decoration: none;
                                            cursor: pointer;

                                            &:hover {
                                                color: $colorBlue
                                            }
                                        }

                                    }
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom {
                                display: grid;
                                grid-template-columns: repeat(4, minmax(0, 1fr));
                                justify-content: space-between;
                                // align-items: center;
                                border: 2px solid $colorExtraLightGray;
                                border-top: none;
                                width: 95%;
                                padding: 4px 12px;
                                // border-radius-t: $borderRadiusL;
                                border-bottom-left-radius: $borderRadiusL;
                                border-bottom-right-radius: $borderRadiusL;
                                margin-left: 8px;
                                // min-height: 50px;
                                height: 40px;
                                transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
                                overflow: hidden;
                                /* Ensures content is hidden when height is 0 */
                                opacity: 1;
                                /* Default opacity */

                                .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom-item {
                                    display: flex;
                                    align-items: center;

                                    .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom-item-label {
                                        margin-right: 4px;
                                    }

                                    .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom-item-link {
                                        color: $colorBlue;
                                    }
                                }

                                &.hidden {
                                    height: 0;
                                    opacity: 0;
                                    padding: 0 12px;
                                }
                            }
                        }


                    }

                    .refferend-list-affiliate__content--middle-content-deposit-withdraw {
                        display: flex;
                        padding: 16px;

                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-left {
                            width: 20%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-top: 1px;
                            background-color: yello;

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-left-time {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                            }

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-left-date {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorGray;
                                opacity: $opacityM;
                                text-transform: capitalize;
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-middle {
                            width: 10%;
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: -32px;

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-middle-icon {
                                padding: 8px 8px;
                                width: fit-content;
                                height: fit-content;
                                border-radius: $borderRadiusFull;

                                &.up {
                                    background-color: $colorExtraLightGreen;
                                }

                                &.down {
                                    background-color: $colorExtraLightRed;
                                }

                                &.setting {
                                    background-color: $colorExtraLightBlue;
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-middle-line {
                                width: 2px;
                                height: 100%;
                                background-color: $colorExtraLightGray;
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-right {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 75%;

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top {
                                display: flex;
                                justify-content: space-between;
                                border: 2px solid $colorExtraLightGray;
                                padding: 4px 12px;
                                border-radius: $borderRadiusL;
                                margin-left: 8px;
                                width: 100%;
                                min-height: 55px;

                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-title {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                }

                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-money {
                                    display: flex;
                                    align-items: center;
                                    gap: $gapXS;

                                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-money-title {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightL;
                                        color: $colorGray;
                                        opacity: $opacityM;
                                    }
                                }

                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-transiction {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    align-items: end;

                                    // padding-top: 4px;
                                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-transiction-number {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightXL;
                                        padding: 4px 8px;
                                        width: fit-content;
                                        border-radius: $borderRadiusL;
                                        white-space: nowrap;

                                        &.up {
                                            color: $colorGreen;
                                            background-color: $colorExtraLightGreen;
                                        }

                                        &.down {
                                            color: $colorRed;
                                            background-color: $colorExtraLightRed;
                                        }
                                    }

                                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-transiction-detail {
                                        display: flex;
                                        align-items: center;
                                        gap: $gapXS;

                                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-transiction-detail-label {
                                            display: flex;
                                            align-items: center;
                                            gap: $gapXS;
                                            transition: all 0.3s ease;
                                            text-decoration: none;
                                            cursor: pointer;

                                            &:hover {
                                                color: $colorBlue
                                            }
                                        }

                                    }
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-bottom {
                                display: grid;
                                grid-template-columns: repeat(4, minmax(0, 1fr));
                                justify-content: space-between;
                                // align-items: center;
                                border: 2px solid $colorExtraLightGray;
                                border-top: none;
                                width: 95%;
                                padding: 4px 12px;
                                // border-radius-t: $borderRadiusL;
                                border-bottom-left-radius: $borderRadiusL;
                                border-bottom-right-radius: $borderRadiusL;
                                margin-left: 8px;
                                // min-height: 50px;
                                height: 40px;
                                transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
                                overflow: hidden;
                                /* Ensures content is hidden when height is 0 */
                                opacity: 1;
                                /* Default opacity */

                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-bottom-item {
                                    display: flex;
                                    align-items: center;

                                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-bottom-item-label {
                                        margin-right: 4px;
                                    }

                                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-bottom-item-link {
                                        color: $colorBlue;
                                    }
                                }

                                &.hidden {
                                    height: 0;
                                    opacity: 0;
                                    padding: 0 12px;
                                }
                            }
                        }
                    }

                    .refferend-list-affiliate__content--middle-content-refferend-list {
                        display: flex;
                        padding: 16px;

                        .refferend-list-affiliate__content--middle-content-refferend-list-container {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            height: fit-content;
                            max-width: 729px;
                            border: 1px solid $colorViolet;
                            border-radius: $borderRadiusL;


                            .refferend-list-affiliate__content--middle-content-refferend-list-container-persons {
                                display: flex;
                                align-items: center;
                                overflow-x: hidden;
                                white-space: nowrap;
                                /* Ngăn các phần tử con xuống dòng */
                                max-width: 100%;
                                /* Đảm bảo giữ nguyên kích thước phần tử cha */
                                padding: 12px 0px;
                                border-radius: $borderRadiusL;
                                // gap: $gapL;

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person {
                                    display: flex;
                                    border-right: 2px solid $colorMoreLightGray;
                                    padding: 0px 24px;
                                    flex-direction: column;
                                    align-items: center;
                                    gap: $gapS;

                                    &.not-first-item {
                                        border-left: 2px solid $colorMoreLightGray;
                                    }


                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-value {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 70px;
                                        height: 60px;
                                        border-radius: $borderRadiusL;
                                        border: 1px solid $colorViolet;
                                        background-color: $colorExtraLightViolet;

                                        .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-value-unit {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            justify-content: center;
                                            gap: $gapXS;

                                            .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-value-unit-label {
                                                font-weight: $fontWeightL;
                                                font-size: $textFontSizeM;
                                                color: $colorGray;
                                                font-style: italic;
                                                padding: 0px 4px;
                                                border: 1px solid $colorGray;
                                                border-radius: $borderRadiusFull;
                                            }

                                            .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-value-unit-number {
                                                font-weight: $fontWeightXL;
                                                font-size: $textFontSize3XL;
                                                color: $colorBlack;
                                            }
                                        }
                                    }

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-info {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 60px;
                                        height: 60px;
                                        border-radius: $borderRadiusFull;
                                        background-color: $colorViolet;

                                        .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-info-name {
                                            font-weight: $fontWeightXL;
                                            font-size: $textFontSize3XL;
                                            color: $colorWhite;
                                        }

                                        &.adding {
                                            background-color: $colorExtraLightViolet;
                                        }
                                    }

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-counting {
                                        display: flex;
                                        flex-direction: column;
                                        gap: $gapXS;
                                        align-items: center;

                                        span {
                                            font-size: $textFontSizeM;
                                            font-weight: $fontWeightL;
                                            color: $colorBlack;
                                            white-space: nowrap;
                                        }

                                        &.newest-person {
                                            span {
                                                font-weight: $fontWeightXL;
                                            }
                                        }

                                        .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-counting-phone {
                                            font-size: $textFontSizeM;
                                            font-weight: $fontWeightL;
                                            color: $colorLightGray;
                                        }
                                    }
                                }

                            }

                            .refferend-list-affiliate__content--middle-content-refferend-list-container-navigate {
                                position: absolute;
                                top: 20%;

                                &.left {
                                    left: 0;
                                }


                                &.right {
                                    right: 0;
                                }

                                &.hidden {
                                    display: none;
                                }

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-navigate-icon {
                                    font-size: $textFontSize3XL;
                                    background-color: $colorMoreLightGray;
                                    padding: 16px 4px;

                                    &.left {
                                        left: 0;
                                        border-top-right-radius: $borderRadiusL;
                                        border-bottom-right-radius: $borderRadiusL;
                                    }

                                    &.right {
                                        right: 0;
                                        border-top-left-radius: $borderRadiusL;
                                        border-bottom-left-radius: $borderRadiusL;
                                    }
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-refferend-list-container-note {
                                display: flex;
                                gap: $gapL;
                                width: 100%;
                                padding: 16px 32px;
                                background-color: $colorExtraLightViolet;
                                border-bottom-left-radius: $borderRadiusL;
                                border-bottom-right-radius: $borderRadiusL;

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-note-child {
                                    display: flex;
                                    flex-direction: column;
                                    gap: $gapXS;
                                    width: 60%;
                                    // background-color: $colorRed;

                                    span {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightL;
                                        color: $colorBlack;
                                    }

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-note-child-image {
                                        width: 100%;
                                        height: 180px;
                                    }

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-note-child-header {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightXL;
                                        color: $colorBlack;
                                    }

                                    .high-light {
                                        color: $colorOrange;
                                        font-weight: $fontWeightXL;
                                    }
                                }

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-note-image {
                                    width: 40%;

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-note-image-child {
                                        width: 100%;
                                        height: 180px;
                                    }
                                }
                            }
                        }


                    }

                    .refferend-list-affiliate__content--middle-content-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .refferend-list-affiliate__content--middle-content-pagination {
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 16px;
                        padding-right: 16px;
                    }
                }
            }

            .refferend-list-affiliate__content--right {
                width: 25%;
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: $gapL;

                .refferend-list-affiliate__content--right-bank {
                    display: flex;
                    flex-direction: column;
                    background-color: #4158D0;
                    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #feb53d 100%);
                    border-radius: $borderRadiusL;

                    .refferend-list-affiliate__content--right-bank-content {
                        // height: 75%;
                        // width: 100%;
                        // background-color: yellow;
                        display: flex;
                        flex-direction: column;
                        gap: $gapL;
                        padding: 16px;


                        .refferend-list-affiliate__content--right-bank-content-top {
                            display: flex;
                            justify-content: space-between;

                            .refferend-list-affiliate__content--right-bank-content-top-circle {
                                position: relative;

                                .refferend-list-affiliate__content--right-bank-content-top-circle-shape {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 20px;
                                    height: 20px;
                                    border-radius: $borderRadiusFull;

                                    &.orange {
                                        left: 14px;
                                        z-index: 0;
                                        background-color: $colorOrange;
                                        opacity: 0.85;
                                    }

                                    &.red {
                                        background-color: $colorRed;
                                    }
                                }

                                .refferend-list-affiliate__content--right-bank-content-top-circle-name {
                                    position: absolute;
                                    top: 0;
                                    left: 20px;
                                }
                            }

                            .refferend-list-affiliate__content--right-bank-content-top-location {
                                display: flex;
                                align-items: center;
                                gap: $gapXS;

                                .refferend-list-affiliate__content--right-bank-content-top-location-icon {
                                    font-size: $textFontSize2XL;
                                    color: $colorWhite;
                                }

                                .refferend-list-affiliate__content--right-bank-content-top-location-label {
                                    font-weight: $fontWeightXL;
                                    font-size: $textFontSizeM;
                                    color: $colorWhite;
                                }
                            }
                        }

                        .refferend-list-affiliate__content--right-bank-content-middle {
                            display: flex;
                            justify-content: space-between;

                            .refferend-list-affiliate__content--right-bank-content-middle-info {
                                display: flex;
                                flex-direction: column;
                                gap: $gapS;

                                .refferend-list-affiliate__content--right-bank-content-middle-info-label {
                                    font-weight: $fontWeightL;
                                    font-size: $textFontSizeM;
                                    color: $colorMoreLightGray;
                                }

                                .refferend-list-affiliate__content--right-bank-content-middle-info-value {
                                    font-weight: $fontWeightXL;
                                    font-size: $textFontSize3XL;
                                    color: $colorWhite;
                                }
                            }
                        }
                    }

                    .refferend-list-affiliate__content--right-bank-bottom {
                        display: flex;
                        justify-content: space-between;
                        padding: 0px 16px 16px 16px;
                        align-items: center;
                        // border-top: 1px solid $colorLightGray;

                        .refferend-list-affiliate__content--right-bank-bottom-name {
                            font-weight: $fontWeightXL;
                            font-size: $textFontSizeM;
                            color: $colorYellow;
                        }

                        .refferend-list-affiliate__content--right-bank-bottom-icon {
                            display: flex;
                            gap: $gapS;
                            align-items: center;

                            .refferend-list-affiliate__content--right-bank-bottom-icon-icon {
                                padding: 4px;
                                background-color: $colorWhite;
                                border-radius: $borderRadiusFull;
                                font-size: $textFontSizeXL;
                                font-weight: $fontWeightL;
                                color: $colorWhite;
                                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                                cursor: pointer;
                            }

                            .refferend-list-affiliate__content--right-bank-bottom-icon-label {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorWhite;
                            }
                        }
                    }
                }

                .refferend-list-affiliate__content--right-share {
                    display: flex;
                    flex-direction: column;
                    background-color: $colorWhite ;
                    border-radius: $borderRadiusL;

                    .refferend-list-affiliate__content--right-share-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 2px solid $colorExtraLightGray;
                        padding: 12px 12px;

                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }
                    }

                    .refferend-list-affiliate__content--right-share-content {
                        display: flex;
                        flex-direction: column;
                        gap: $gapL;
                        padding: 12px;

                        .refferend-list-affiliate__content--right-share-content-highlight {
                            display: flex;
                            gap: $gapXS;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100px;
                            background-color: $colorMoreLightViolet;
                            border-radius: $borderRadiusL;

                            .refferend-list-affiliate__content--right-share-content-highlight-title {
                                font-size: $textFontSize3XL;
                                font-weight: $fontWeightXL;
                                color: $colorOrange;
                            }

                            .refferend-list-affiliate__content--right-share-content-highlight-sub-title {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                            }
                        }

                        .refferend-list-affiliate__content--right-share-content-share {
                            display: flex;
                            flex-direction: column;

                            .refferend-list-affiliate__content--right-share-content-share-header {
                                display: flex;
                                align-items: center;

                                span {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                }



                            }

                            .refferend-list-affiliate__content--right-share-content-share-link {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .refferend-list-affiliate__content--right-share-content-share-link-url {
                                    max-width: 150px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    cursor: pointer;
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorLightGray;
                                }

                                .refferend-list-affiliate__content--right-share-content-share-link-url-copy {
                                    padding: 1px 8px;
                                    border-radius: $borderRadiusFull;
                                    border: 2px solid $colorViolet;
                                    color: $colorViolet;
                                    transition: 0.3s all ease-out;
                                    cursor: pointer;

                                    &:hover {
                                        background-color: $colorViolet;
                                        color: $colorWhite
                                    }
                                }
                            }

                        }
                    }
                }

                .refferend-list-affiliate__content--right-referral-policy {
                    position: relative;
                    height: fit-content;
                    width: 100%;

                    .refferend-list-affiliate__content--right-referral-policy-image {
                        height: 100%;
                        width: 100%;
                    }

                    .refferend-list-affiliate__content--right-referral-policy-content {
                        display: flex;
                        flex-direction: column;
                        top: 50%;
                        left: 70%;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        width: 50%;
                        // background-color: yellow;

                        .refferend-list-affiliate__content--right-referral-policy-content-header {
                            font-weight: $fontWeightXL;
                            font-size: $textFontSizeM;
                            color: $colorWhite;
                        }

                        .refferend-list-affiliate__content--right-referral-policy-content-describe {
                            display: none;
                            font-weight: $fontWeightL;
                            font-size: $textFontSizeM;
                            color: $colorMoreLightGray
                        }

                        .refferend-list-affiliate__content--right-referral-policy-content-read-more {
                            margin-top: 2px;
                            display: flex;
                            gap: $gapXS;
                            align-items: center;
                            font-weight: $fontWeightXL;
                            font-size: $textFontSizeM;
                            color: $colorOrange;
                            padding: 4px 8px;
                            background-color: $colorExtraLightOrange;
                            width: fit-content;
                            border-radius: $borderRadiusL;
                        }
                    }
                }
            }
        }

        .refferend-list-affiliate__footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: $colorWhite;
            background-image: none;
            width: 100%;
            height: 30vh;

            .refferend-list-affiliate__footer--container {
                display: flex;
                flex-direction: column;
                // align-items: center;
                justify-content: center;
                width: 90%;
                height: 100%;

                .refferend-list-affiliate__footer--container-information {
                    display: flex;
                    align-items: center;
                    padding: 0px;

                    .refferend-list-affiliate__footer--container-information-item {
                        width: 25%;
                        height: fit-content;

                        img {
                            width: 100px;
                            height: fit-content;
                        }

                        span {
                            font-size: $textFontSize2XL;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;

                            .high-light {
                                color: $colorViolet
                            }
                        }
                    }
                }

                .refferend-list-affiliate__footer--container-copy-right {
                    display: flex;
                    align-items: center;
                    padding: 16px;
                    gap: $gapL;
                    border-top: 1px solid $colorBlack;

                    .refferend-list-affiliate__footer--container-copy-right-item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30%;
                        height: fit-content;

                        img {
                            width: 150px;
                            height: fit-content;
                            cursor: pointer;
                        }

                        span {
                            font-size: $textFontSize2XL;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;

                            .high-light {
                                color: $colorViolet
                            }
                        }
                    }
                }
            }

        }
    }


}

@media screen and (max-width: 1024px) {
    .refferend-list-affiliate {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        width: 100%;

        .refferend-list-affiliate__content {
            display: flex;
            flex-direction: column-reverse;
            padding: 16px;
            gap: $gapL;
            width: 100%;

            .refferend-list-affiliate__content--left {
                display: flex;
                flex-direction: column;
                gap: $gapL;
                width: 100%;
                height: fit-content;

                .refferend-list-affiliate__content--left-card {
                    // position: relative;
                    display: flex;
                    flex-direction: column;
                    padding: 12px;
                    background-color: $colorWhite;
                    border-radius: $borderRadiusL;

                    &.no-padding {
                        padding: 0px;
                    }

                    &.border-left-highlight {
                        border-left: 5px solid;
                    }

                    &.border-blue {
                        border-color: $colorBlue;
                    }

                    &.border-green {
                        border-color: $colorGreen;
                    }

                    &.border-red {
                        border-color: $colorRed;
                    }

                    .refferend-list-affiliate__content--left-card-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 2px solid $colorExtraLightGray;
                        padding: 12px 12px;

                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }
                    }

                    .refferend-list-affiliate__content--left-card-body {
                        display: flex;
                        flex-direction: column;
                        padding: 12px;
                        gap: $gapM;

                        .refferend-list-affiliate__content--left-card-body-image {
                            width: 100%;
                            height: 100px;
                            background-color: #D16FCC;
                            border-radius: $borderRadiusL;
                        }

                        .refferend-list-affiliate__content--left-card-body-steps {
                            // position: relative;
                            display: flex;
                            // align-items: center;
                            gap: $gapS;
                            min-height: 48px;
                            margin-bottom: -12px;
                            // background-color: $colorExtraLightYellow;

                            .refferend-list-affiliate__content--left-card-body-steps-count {
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                .refferend-list-affiliate__content--left-card-body-steps-count-circle {
                                    // width: 28px;
                                    // height: 28px;
                                    padding: 6px 12px;
                                    border-radius: $borderRadiusFull;
                                    background-color: $colorViolet;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: white;
                                    font-size: $textFontSizeM;
                                }

                                .refferend-list-affiliate__content--left-card-body-steps-count-line {
                                    width: 2px;
                                    height: 100%;
                                    background-color: $colorMoreLightViolet;
                                }
                            }


                            .refferend-list-affiliate__content--left-card-body-steps-step {
                                width: 85%;

                                span {
                                    font-weight: $fontWeightL;
                                    font-size: $textFontSizeM;
                                    color: $colorBlack;
                                }

                            }
                        }

                        .refferend-list-affiliate__content--left-card-body-code {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            gap: $gapXS;
                            // margin-top: 12px;

                            .refferend-list-affiliate__content--left-card-body-code-content {
                                display: flex;
                                justify-content: space-between;
                                padding: 12px 24px;
                                width: 90%;
                                background-color: $colorMoreLightViolet;
                                border-radius: $borderRadiusFull;

                                .refferend-list-affiliate__content--left-card-body-code-content-label {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                }

                                .refferend-list-affiliate__content--left-card-body-code-content-copy {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorViolet;
                                    cursor: pointer;
                                    transition: all 0.3s ease-out;

                                    &.not-allow-save {
                                        color: $colorLightGray;
                                        cursor: not-allowed;

                                        &:hover {
                                            color: $colorLightGray;
                                        }
                                    }

                                    &:hover {
                                        color: $colorDarkViolet;
                                    }
                                }

                                .refferend-list-affiliate__content--left-card-body-code-content-input {
                                    background-color: transparent;
                                    outline: none;
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                    width: 70%;
                                }
                            }

                            .refferend-list-affiliate__content--left-card-body-code-random {
                                display: flex;
                                justify-content: center;
                                padding: 12px 24px;
                                width: 90%;
                                background-color: $colorViolet;
                                border-radius: $borderRadiusFull;
                                cursor: pointer;
                                transition: all 0.3s ease-out;

                                &:hover {
                                    background-color: $colorDarkViolet;
                                }

                                .refferend-list-affiliate__content--left-card-body-code-random-label {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorWhite;
                                }
                            }
                        }
                    }

                    .refferend-list-affiliate__content--left-card-statistic {
                        display: flex;
                        flex-direction: column;

                        .refferend-list-affiliate__content--left-card-statistic-header {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 4px 8px;
                            border-radius: $borderRadiusL;
                            color: $colorWhite;
                            font-size: $textFontSize3XL;

                            span {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                            }

                            &.green {
                                background-color: $colorGreen;
                            }

                            &.red {
                                background-color: $colorRed;
                            }

                            &.blue {
                                background-color: $colorExtraLightBlue;
                                color: $colorBlue;
                            }
                        }

                        .refferend-list-affiliate__content--left-card-statistic-content {
                            display: flex;
                            flex-direction: column;
                            // background-color: $colorExtraLightGreen;
                            padding: 12px;

                            .refferend-list-affiliate__content--left-card-statistic-content-value {

                                .refferend-list-affiliate__content--left-card-statistic-content-value-number {
                                    font-size: $textFontSize3XL;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                    padding-right: 4px;
                                }

                                .refferend-list-affiliate__content--left-card-statistic-content-value-unit {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorBlack;
                                }
                            }

                            .refferend-list-affiliate__content--left-card-statistic-content-previous {
                                span {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorLightGray;
                                }
                            }
                        }
                    }

                    .refferend-list-affiliate__content--left-card-content {
                        display: flex;
                        justify-content: space-between;

                        .refferend-list-affiliate__content--left-card-content-describe {
                            display: flex;
                            flex-direction: column;
                            gap: $gapXS;

                            .refferend-list-affiliate__content--left-card-content-describe-label {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                            }

                            .refferend-list-affiliate__content--left-card-content-describe-value {
                                font-size: $textFontSize3XL;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;

                                .unit {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorBlack;
                                }
                            }
                        }

                        .refferend-list-affiliate__content--left-card-content-icon {
                            padding: 8px;
                            font-size: $textFontSize3XL;
                            border-radius: $borderRadiusFull;
                            width: fit-content;
                            height: fit-content;

                            &.blue {
                                color: $colorBlue;
                                background-color: $colorExtraLightBlue;
                            }


                            &.red {
                                color: $colorRed;
                                background-color: $colorExtraLightRed;
                            }

                            &.yellow {
                                color: $colorYellow;
                                background-color: $colorExtraLightYellow;
                            }


                            &.green {
                                color: $colorGreen;
                                background-color: $colorExtraLightGreen;
                            }
                        }
                    }

                    .refferend-list-affiliate__content--left-card-previous {
                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightL;
                            color: $colorLightGray;
                        }
                    }
                }

                .refferend-list-affiliate__content--left-card-share-link {
                    display: flex;
                    flex-direction: column;
                    background-color: $colorWhite ;
                    border-radius: $borderRadiusL;

                    .refferend-list-affiliate__content--left-card-share-link-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 2px solid $colorExtraLightGray;
                        padding: 12px 12px;

                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }
                    }

                    .refferend-list-affiliate__content--left-card-share-link-content {
                        display: flex;
                        flex-direction: column;
                        gap: $gapL;
                        padding: 12px;

                        .refferend-list-affiliate__content--left-card-share-link-content-highlight {
                            display: flex;
                            gap: $gapXS;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100px;
                            background-color: $colorMoreLightViolet;
                            border-radius: $borderRadiusL;

                            .refferend-list-affiliate__content--left-card-share-link-content-highlight-title {
                                font-size: $textFontSize3XL;
                                font-weight: $fontWeightXL;
                                color: $colorOrange;
                            }

                            .refferend-list-affiliate__content--left-card-share-link-content-highlight-sub-title {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                            }
                        }

                        .refferend-list-affiliate__content--left-card-share-link-content-share {
                            display: flex;
                            flex-direction: column;

                            .refferend-list-affiliate__content--left-card-share-link-content-share-header {
                                display: flex;
                                align-items: center;

                                span {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                }



                            }

                            .refferend-list-affiliate__content--left-card-share-link-content-share-link {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .refferend-list-affiliate__content--left-card-share-link-content-share-link-url {
                                    max-width: 200px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    cursor: pointer;
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorLightGray;
                                }

                                .refferend-list-affiliate__content--left-card-share-link-content-share-link-url-copy {
                                    padding: 1px 8px;
                                    border-radius: $borderRadiusFull;
                                    border: 2px solid $colorViolet;
                                    color: $colorViolet;
                                    transition: 0.3s all ease-out;
                                    cursor: pointer;

                                    &:hover {
                                        background-color: $colorViolet;
                                        color: $colorWhite
                                    }
                                }
                            }

                        }
                    }
                }

                .refferend-list-affiliate__content--left-icon {
                    padding: 8px;
                    font-size: $textFontSize3XL;
                    border-radius: $borderRadiusFull;

                    &.blue {
                        color: $colorBlue;
                        background-color: $colorExtraLightBlue;
                    }


                    &.red {
                        color: $colorRed;
                        background-color: $colorExtraLightRed;
                    }

                    &.yellow {
                        color: $colorYellow;
                        background-color: $colorExtraLightYellow;
                    }


                    &.green {
                        color: $colorGreen;
                        background-color: $colorExtraLightGreen;
                    }
                }

            }

            .refferend-list-affiliate__content--middle {
                display: flex;
                flex-direction: column;
                gap: $gapL;
                width: 100%;
                max-width: 100%;
                height: fit-content;

                .refferend-list-affiliate__content--middle-content {
                    border-radius: $borderRadiusL;
                    background-color: $colorWhite;

                    .refferend-list-affiliate__content--middle-content-header {
                        display: flex;
                        flex-direction: column;
                        gap: $gapXS;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 2px solid $colorExtraLightGray;
                        padding: 12px;

                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }

                        .refferend-list-affiliate__content--middle-content-header-options {
                            display: flex;
                            gap: $gapS;

                            .refferend-list-affiliate__content--middle-content-header-options-tab {
                                padding: 6px 4px;
                                background-color: $colorMoreLightGray;
                                color: $colorBlack;
                                border-radius: $borderRadiusL;
                                cursor: pointer;
                                transition: 0.3s all ease;

                                &.activated {
                                    background-color: $colorViolet;
                                    color: $colorWhite;
                                }
                            }
                        }
                    }

                    .refferend-list-affiliate__content--middle-content-history-receiving {
                        display: flex;
                        padding: 16px;

                        .refferend-list-affiliate__content--middle-content-history-receiving-left {
                            width: 20%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-top: 1px;

                            .refferend-list-affiliate__content--middle-content-history-receiving-left-time {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                            }

                            .refferend-list-affiliate__content--middle-content-history-receiving-left-date {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorGray;
                                opacity: $opacityM;
                                text-transform: capitalize;
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-history-receiving-middle {
                            width: 10%;
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: -32px;

                            .refferend-list-affiliate__content--middle-content-history-receiving-middle-icon {
                                padding: 8px 8px;
                                width: fit-content;
                                height: fit-content;
                                border-radius: $borderRadiusFull;

                                &.up {
                                    background-color: $colorExtraLightGreen;
                                }

                                &.down {
                                    background-color: $colorExtraLightRed;
                                }

                                &.setting {
                                    background-color: $colorExtraLightBlue;
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-history-receiving-middle-line {
                                width: 2px;
                                height: 100%;
                                background-color: $colorExtraLightGray;
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-history-receiving-right {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 75%;

                            .refferend-list-affiliate__content--middle-content-history-receiving-right-top {
                                display: flex;
                                justify-content: space-between;
                                border: 2px solid $colorExtraLightGray;
                                padding: 4px 12px;
                                border-radius: $borderRadiusL;
                                margin-left: 8px;
                                width: 100%;
                                min-height: 55px;

                                .refferend-list-affiliate__content--middle-content-history-receiving-right-top-title {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                }

                                .refferend-list-affiliate__content--middle-content-history-receiving-right-top-money {
                                    display: flex;
                                    align-items: center;
                                    gap: $gapXS;

                                    .refferend-list-affiliate__content--middle-content-history-receiving-right-top-money-title {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightL;
                                        color: $colorGray;
                                        opacity: $opacityM;
                                    }
                                }

                                .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    align-items: end;

                                    // padding-top: 4px;
                                    .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction-number {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightXL;
                                        padding: 4px 8px;
                                        width: fit-content;
                                        border-radius: $borderRadiusL;
                                        white-space: nowrap;

                                        &.up {
                                            color: $colorGreen;
                                            background-color: $colorExtraLightGreen;
                                        }

                                        &.down {
                                            color: $colorRed;
                                            background-color: $colorExtraLightRed;
                                        }

                                        &.none {
                                            color: transparent;
                                            background-color: transparent;
                                        }
                                    }

                                    .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction-detail {
                                        display: flex;
                                        align-items: center;
                                        gap: $gapXS;

                                        .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction-detail-label {
                                            display: flex;
                                            align-items: center;
                                            gap: $gapXS;
                                            transition: all 0.3s ease;
                                            text-decoration: none;
                                            cursor: pointer;

                                            &:hover {
                                                color: $colorBlue
                                            }
                                        }

                                    }
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom {
                                display: grid;
                                grid-template-columns: repeat(4, minmax(0, 1fr));
                                justify-content: space-between;
                                // align-items: center;
                                border: 2px solid $colorExtraLightGray;
                                border-top: none;
                                width: 95%;
                                padding: 4px 12px;
                                // border-radius-t: $borderRadiusL;
                                border-bottom-left-radius: $borderRadiusL;
                                border-bottom-right-radius: $borderRadiusL;
                                margin-left: 8px;
                                // min-height: 50px;
                                height: 40px;
                                transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
                                overflow: hidden;
                                /* Ensures content is hidden when height is 0 */
                                opacity: 1;
                                /* Default opacity */

                                .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom-item {
                                    display: flex;
                                    align-items: center;

                                    .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom-item-label {
                                        margin-right: 4px;
                                    }

                                    .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom-item-link {
                                        color: $colorBlue;
                                    }
                                }

                                &.hidden {
                                    height: 0;
                                    opacity: 0;
                                    padding: 0 12px;
                                }
                            }
                        }
                    }

                    .refferend-list-affiliate__content--middle-content-deposit-withdraw {
                        display: flex;
                        padding: 16px;

                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-left {
                            width: 20%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-top: 1px;
                            background-color: yello;

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-left-time {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                            }

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-left-date {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorGray;
                                opacity: $opacityM;
                                text-transform: capitalize;
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-middle {
                            width: 10%;
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: -32px;

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-middle-icon {
                                padding: 8px 8px;
                                width: fit-content;
                                height: fit-content;
                                border-radius: $borderRadiusFull;

                                &.up {
                                    background-color: $colorExtraLightGreen;
                                }

                                &.down {
                                    background-color: $colorExtraLightRed;
                                }

                                &.setting {
                                    background-color: $colorExtraLightBlue;
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-middle-line {
                                width: 2px;
                                height: 100%;
                                background-color: $colorExtraLightGray;
                            }
                        }

                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-right {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 75%;

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top {
                                display: flex;
                                justify-content: space-between;
                                border: 2px solid $colorExtraLightGray;
                                padding: 4px 12px;
                                border-radius: $borderRadiusL;
                                margin-left: 8px;
                                width: 100%;
                                min-height: 55px;

                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-title {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                }

                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-money {
                                    display: flex;
                                    align-items: center;
                                    gap: $gapXS;

                                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-money-title {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightL;
                                        color: $colorGray;
                                        opacity: $opacityM;
                                    }
                                }

                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-transiction {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    align-items: end;

                                    // padding-top: 4px;
                                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-transiction-number {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightXL;
                                        padding: 4px 8px;
                                        width: fit-content;
                                        border-radius: $borderRadiusL;
                                        white-space: nowrap;

                                        &.up {
                                            color: $colorGreen;
                                            background-color: $colorExtraLightGreen;
                                        }

                                        &.down {
                                            color: $colorRed;
                                            background-color: $colorExtraLightRed;
                                        }
                                    }

                                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-transiction-detail {
                                        display: flex;
                                        align-items: center;
                                        gap: $gapXS;

                                        .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-top-transiction-detail-label {
                                            display: flex;
                                            align-items: center;
                                            gap: $gapXS;
                                            transition: all 0.3s ease;
                                            text-decoration: none;
                                            cursor: pointer;

                                            &:hover {
                                                color: $colorBlue
                                            }
                                        }

                                    }
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-bottom {
                                display: grid;
                                grid-template-columns: repeat(4, minmax(0, 1fr));
                                justify-content: space-between;
                                // align-items: center;
                                border: 2px solid $colorExtraLightGray;
                                border-top: none;
                                width: 95%;
                                padding: 4px 12px;
                                // border-radius-t: $borderRadiusL;
                                border-bottom-left-radius: $borderRadiusL;
                                border-bottom-right-radius: $borderRadiusL;
                                margin-left: 8px;
                                // min-height: 50px;
                                height: 40px;
                                transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
                                overflow: hidden;
                                /* Ensures content is hidden when height is 0 */
                                opacity: 1;
                                /* Default opacity */

                                .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-bottom-item {
                                    display: flex;
                                    align-items: center;

                                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-bottom-item-label {
                                        margin-right: 4px;
                                    }

                                    .refferend-list-affiliate__content--middle-content-deposit-withdraw-right-bottom-item-link {
                                        color: $colorBlue;
                                    }
                                }

                                &.hidden {
                                    height: 0;
                                    opacity: 0;
                                    padding: 0 12px;
                                }
                            }
                        }
                    }

                    .refferend-list-affiliate__content--middle-content-refferend-list {
                        display: flex;
                        padding: 16px;

                        .refferend-list-affiliate__content--middle-content-refferend-list-container {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            height: fit-content;
                            max-width: fit-content;
                            border: 1px solid $colorViolet;
                            border-radius: $borderRadiusL;


                            .refferend-list-affiliate__content--middle-content-refferend-list-container-persons {
                                display: flex;
                                align-items: center;
                                overflow-x: hidden;
                                white-space: nowrap;
                                max-width: 100%;
                                padding: 12px 0px;
                                border-radius: $borderRadiusL;

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person {
                                    display: inline-flex;
                                    flex: 0 0 20%;
                                    border-right: 1px solid $colorMoreLightGray;
                                    flex-direction: column;
                                    align-items: center;
                                    padding: 0px 12px;

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-value {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 55px;
                                        height: 50px;
                                        border-radius: $borderRadiusL;
                                        border: 1px solid $colorViolet;
                                        background-color: $colorExtraLightViolet;

                                        .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-value-unit {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            justify-content: center;
                                            gap: $gapXS;

                                            .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-value-unit-label {
                                                font-weight: $fontWeightL;
                                                font-size: $textFontSizeS;
                                                color: $colorGray;
                                                font-style: italic;
                                                padding: 0px 4px;
                                                border: 1px solid $colorGray;
                                                border-radius: $borderRadiusFull;
                                            }

                                            .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-value-unit-number {
                                                font-weight: $fontWeightXL;
                                                font-size: $textFontSizeL;
                                                color: $colorBlack;
                                            }
                                        }
                                    }

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-info {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 40px;
                                        height: 40px;
                                        border-radius: $borderRadiusFull;
                                        background-color: $colorViolet;

                                        .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-info-name {
                                            font-weight: $fontWeightXL;
                                            font-size: $textFontSizeL;
                                            color: $colorWhite;
                                        }
                                    }

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-counting {
                                        display: flex;
                                        flex-direction: column;
                                        gap: $gapXS;
                                        align-items: center;

                                        span {
                                            font-size: $textFontSizeS;
                                            font-weight: $fontWeightL;
                                            color: $colorBlack;
                                            white-space: nowrap;
                                        }

                                        &.newest-person {
                                            span {
                                                font-weight: $fontWeightXL;
                                            }
                                        }

                                        .refferend-list-affiliate__content--middle-content-refferend-list-container-persons-person-counting-phone {
                                            font-size: $textFontSizeS;
                                            font-weight: $fontWeightL;
                                            color: $colorLightGray;
                                        }
                                    }
                                }

                            }

                            .refferend-list-affiliate__content--middle-content-refferend-list-container-navigate {
                                position: absolute;
                                top: 12%;

                                &.left {
                                    left: 0;
                                }


                                &.right {
                                    right: 0;
                                }

                                &.hidden {
                                    display: none;
                                }

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-navigate-icon {
                                    font-size: $textFontSizeXL;
                                    background-color: $colorMoreLightGray;
                                    padding: 18px 4px;

                                    &.left {
                                        left: 0;
                                        border-top-right-radius: $borderRadiusL;
                                        border-bottom-right-radius: $borderRadiusL;
                                    }

                                    &.right {
                                        right: 0;
                                        border-top-left-radius: $borderRadiusL;
                                        border-bottom-left-radius: $borderRadiusL;
                                    }
                                }
                            }

                            .refferend-list-affiliate__content--middle-content-refferend-list-container-note {
                                display: flex;
                                flex-direction: column;
                                gap: $gapL;
                                width: 100%;
                                padding: 16px 16px;
                                background-color: $colorExtraLightViolet;
                                border-bottom-left-radius: $borderRadiusL;
                                border-bottom-right-radius: $borderRadiusL;

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-note-child {
                                    display: flex;
                                    flex-direction: column;
                                    gap: $gapXS;
                                    width: 100%;
                                    // background-color: $colorRed;

                                    span {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightL;
                                        color: $colorBlack;
                                    }

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-note-child-image {
                                        width: 100%;
                                        height: 180px;
                                    }

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-note-child-header {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightXL;
                                        color: $colorBlack;
                                    }

                                    .high-light {
                                        color: $colorOrange;
                                        font-weight: $fontWeightXL;
                                    }
                                }

                                .refferend-list-affiliate__content--middle-content-refferend-list-container-note-image {
                                    width: 100%;

                                    .refferend-list-affiliate__content--middle-content-refferend-list-container-note-image-child {
                                        width: 100%;
                                        height: 180px;
                                    }
                                }
                            }
                        }


                    }

                    .refferend-list-affiliate__content--middle-content-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .refferend-list-affiliate__content--middle-content-pagination {
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 16px;
                        padding-right: 16px;
                    }
                }
            }

            .refferend-list-affiliate__content--right {
                width: 100%;
                height: fit-content;
                display: flex;
                flex-direction: column;
                gap: $gapL;

                .refferend-list-affiliate__content--right-bank {
                    display: flex;
                    flex-direction: column;
                    background-color: #4158D0;
                    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #feb53d 100%);
                    border-radius: $borderRadiusL;

                    .refferend-list-affiliate__content--right-bank-content {
                        // height: 75%;
                        // width: 100%;
                        // background-color: yellow;
                        display: flex;
                        flex-direction: column;
                        gap: $gapL;
                        padding: 16px;


                        .refferend-list-affiliate__content--right-bank-content-top {
                            display: flex;
                            justify-content: space-between;

                            .refferend-list-affiliate__content--right-bank-content-top-circle {
                                position: relative;

                                .refferend-list-affiliate__content--right-bank-content-top-circle-shape {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 20px;
                                    height: 20px;
                                    border-radius: $borderRadiusFull;

                                    &.orange {
                                        left: 14px;
                                        z-index: 0;
                                        background-color: $colorOrange;
                                        opacity: 0.85;
                                    }

                                    &.red {
                                        background-color: $colorRed;
                                    }
                                }

                                .refferend-list-affiliate__content--right-bank-content-top-circle-name {
                                    position: absolute;
                                    top: 0;
                                    left: 20px;
                                }
                            }

                            .refferend-list-affiliate__content--right-bank-content-top-location {
                                display: flex;
                                align-items: center;
                                gap: $gapXS;

                                .refferend-list-affiliate__content--right-bank-content-top-location-icon {
                                    font-size: $textFontSize2XL;
                                    color: $colorWhite;
                                }

                                .refferend-list-affiliate__content--right-bank-content-top-location-label {
                                    font-weight: $fontWeightXL;
                                    font-size: $textFontSizeM;
                                    color: $colorWhite;
                                }
                            }
                        }

                        .refferend-list-affiliate__content--right-bank-content-middle {
                            display: flex;
                            justify-content: space-between;

                            .refferend-list-affiliate__content--right-bank-content-middle-info {
                                display: flex;
                                flex-direction: column;
                                gap: $gapS;

                                .refferend-list-affiliate__content--right-bank-content-middle-info-label {
                                    font-weight: $fontWeightL;
                                    font-size: $textFontSizeM;
                                    color: $colorMoreLightGray;
                                }

                                .refferend-list-affiliate__content--right-bank-content-middle-info-value {
                                    font-weight: $fontWeightXL;
                                    font-size: $textFontSize3XL;
                                    color: $colorWhite;
                                }
                            }
                        }
                    }

                    .refferend-list-affiliate__content--right-bank-bottom {
                        display: flex;
                        justify-content: space-between;
                        padding: 0px 16px 16px 16px;
                        align-items: center;
                        // border-top: 1px solid $colorLightGray;

                        .refferend-list-affiliate__content--right-bank-bottom-name {
                            font-weight: $fontWeightXL;
                            font-size: $textFontSizeM;
                            color: $colorYellow;
                        }

                        .refferend-list-affiliate__content--right-bank-bottom-icon {
                            display: flex;
                            gap: $gapS;
                            align-items: center;

                            .refferend-list-affiliate__content--right-bank-bottom-icon-icon {
                                padding: 4px;
                                background-color: $colorWhite;
                                border-radius: $borderRadiusFull;
                                font-size: $textFontSizeXL;
                                font-weight: $fontWeightL;
                                color: $colorWhite;
                                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                                cursor: pointer;
                            }

                            .refferend-list-affiliate__content--right-bank-bottom-icon-label {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightL;
                                color: $colorWhite;
                            }
                        }
                    }
                }

                .refferend-list-affiliate__content--right-share {
                    display: none;
                    flex-direction: column;
                    background-color: $colorWhite ;
                    border-radius: $borderRadiusL;

                    .refferend-list-affiliate__content--right-share-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 2px solid $colorExtraLightGray;
                        padding: 12px 12px;

                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;
                        }
                    }

                    .refferend-list-affiliate__content--right-share-content {
                        display: flex;
                        flex-direction: column;
                        gap: $gapL;
                        padding: 12px;

                        .refferend-list-affiliate__content--right-share-content-highlight {
                            display: flex;
                            gap: $gapXS;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100px;
                            background-color: $colorMoreLightViolet;
                            border-radius: $borderRadiusL;

                            .refferend-list-affiliate__content--right-share-content-highlight-title {
                                font-size: $textFontSize3XL;
                                font-weight: $fontWeightXL;
                                color: $colorOrange;
                            }

                            .refferend-list-affiliate__content--right-share-content-highlight-sub-title {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                            }
                        }

                        .refferend-list-affiliate__content--right-share-content-share {
                            display: flex;
                            flex-direction: column;

                            .refferend-list-affiliate__content--right-share-content-share-header {
                                display: flex;
                                align-items: center;

                                span {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                }



                            }

                            .refferend-list-affiliate__content--right-share-content-share-link {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .refferend-list-affiliate__content--right-share-content-share-link-url {
                                    max-width: 200px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    cursor: pointer;
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightL;
                                    color: $colorLightGray;
                                }

                                .refferend-list-affiliate__content--right-share-content-share-link-url-copy {
                                    padding: 1px 8px;
                                    border-radius: $borderRadiusFull;
                                    border: 2px solid $colorViolet;
                                    color: $colorViolet;
                                    transition: 0.3s all ease-out;
                                    cursor: pointer;

                                    &:hover {
                                        background-color: $colorViolet;
                                        color: $colorWhite
                                    }
                                }
                            }

                        }
                    }
                }

                .refferend-list-affiliate__content--right-referral-policy {
                    position: relative;
                    height: fit-content;
                    width: 100%;
                    background-color: #893DD9;
                    border-radius: $borderRadiusL;

                    .refferend-list-affiliate__content--right-referral-policy-image {
                        height: 100%;
                        width: 100%;
                    }

                    .refferend-list-affiliate__content--right-referral-policy-content {
                        display: flex;
                        flex-direction: column;
                        top: 50%;
                        left: 70%;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        width: 50%;
                        // background-color: yellow;

                        .refferend-list-affiliate__content--right-referral-policy-content-header {
                            font-weight: $fontWeightXL;
                            font-size: $textFontSizeM;
                            color: $colorWhite;
                        }

                        .refferend-list-affiliate__content--right-referral-policy-content-describe {
                            display: flex;
                            font-weight: $fontWeightL;
                            font-size: $textFontSizeM;
                            color: $colorMoreLightGray
                        }

                        .refferend-list-affiliate__content--right-referral-policy-content-read-more {
                            margin-top: 2px;
                            display: flex;
                            gap: $gapXS;
                            align-items: center;
                            font-weight: $fontWeightXL;
                            font-size: $textFontSizeM;
                            color: $colorOrange;
                            padding: 4px 8px;
                            background-color: $colorExtraLightOrange;
                            width: fit-content;
                            border-radius: $borderRadiusL;
                        }
                    }
                }
            }
        }

        .refferend-list-affiliate__footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: $colorWhite;
            background-image: none;
            width: 100%;
            height: fit-content;

            .refferend-list-affiliate__footer--container {
                display: flex;
                flex-direction: column;
                // align-items: center;
                justify-content: center;
                width: 95%;
                height: 100%;

                .refferend-list-affiliate__footer--container-information {
                    display: flex;
                    flex-direction: column;
                    gap: $gapL;
                    align-items: start;
                    padding: 0px;

                    .refferend-list-affiliate__footer--container-information-item {
                        width: 100%;
                        height: fit-content;

                        img {
                            width: 100px;
                            height: fit-content;
                        }

                        span {
                            font-size: $textFontSize2XL;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;

                            .high-light {
                                color: $colorViolet
                            }
                        }
                    }
                }

                .refferend-list-affiliate__footer--container-copy-right {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    padding: 16px;
                    gap: $gapL;
                    border-top: 1px solid $colorBlack;

                    .refferend-list-affiliate__footer--container-copy-right-item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: fit-content;

                        img {
                            width: 150px;
                            height: fit-content;
                            cursor: pointer;
                        }

                        span {
                            font-size: $textFontSize2XL;
                            font-weight: $fontWeightXL;
                            color: $colorBlack;

                            .high-light {
                                color: $colorViolet
                            }
                        }
                    }
                }
            }

        }
    }

}

@media screen and (max-width: 768px) {
    .refferend-list-affiliate__share-link {
        display: flex;
        flex-direction: column;
        gap: $gapL;

        .refferend-list-affiliate__share-link--qr-code {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 24px;
            padding: 20px 8px;
            border: 2px solid $colorMoreLightGray;
            border-radius: $borderRadiusL;

            .refferend-list-affiliate__share-link--qr-code-child {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: $gapM;
                position: relative;
                background-color: $colorMoreLightViolet;
                border-radius: $borderRadiusL;
                padding: 12px;

                .refferend-list-affiliate__share-link--qr-code-child-download {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    padding: 4px;
                    background-color: $colorMoreLightGray;
                    border-radius: $borderRadiusFull;
                    cursor: pointer;
                }

                .refferend-list-affiliate__share-link--qr-code-child-container {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $colorLightViolet;
                    border: 8px solid $colorBlack;
                    border-radius: $borderRadiusL;
                    width: 400px;
                    height: 400px;

                    .refferend-list-affiliate__share-link--qr-code-child-container-rouded-border-vertical {
                        position: absolute;
                        background-color: $colorLightViolet;
                        width: 330px;
                        height: 400px;
                    }


                    .refferend-list-affiliate__share-link--qr-code-child-container-rouded-border-horizontal {
                        position: absolute;
                        background-color: $colorLightViolet;
                        width: 400px;
                        height: 330px;
                    }

                    .refferend-list-affiliate__share-link--qr-code-child-container-rouded-border-qr-code {
                        position: absolute;
                    }


                }

                .refferend-list-affiliate__share-link--qr-code-child-title {
                    font-size: 1.1rem;
                    font-weight: $fontWeight2XL;
                    color: $colorBlack;
                    text-transform: uppercase;
                    font-family: 'Work Sans', sans-serif;
                    padding-top: 12px;
                }
            }
        }

        .refferend-list-affiliate__share-link--example {
            display: flex;
            flex-direction: column;
            border: 1px solid $colorMoreLightGray;
            border-radius: $borderRadiusL;

            .refferend-list-affiliate__share-link--example-header {
                display: flex;
                justify-content: space-between;

                .refferend-list-affiliate__share-link--example-header-left {
                    background-color: $colorExtraLightOrange;
                    border-top-left-radius: $borderRadiusL;
                    border-bottom-right-radius: $borderRadiusL;
                    padding: 8px;
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightXL;
                    color: $colorBlack;
                }

                .refferend-list-affiliate__share-link--example-header-right {
                    display: flex;
                    align-items: center;
                    gap: $gapXS;
                    padding: 8px;
                    color: $colorOrange;
                }
            }

            .refferend-list-affiliate__share-link--example-body {
                display: flex;
                flex-direction: column;
                padding: 8px;

                span {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorBlack;

                    .high-light {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightL;
                        color: $colorViolet;
                    }
                }

            }
        }

        .refferend-list-affiliate__share-link--social {
            display: flex;
            gap: $gapL;

            .refferend-list-affiliate__share-link--social-child {
                display: flex;
                align-items: center;
                flex-direction: column;

                .refferend-list-affiliate__share-link--social-child-circle {
                    background-color: $colorMoreLightGray;
                    border-radius: $borderRadiusFull;
                    padding: 8px;
                }

                .refferend-list-affiliate__share-link--social-child-label {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorBlack;
                }
            }
        }
    }
}